import { CardContent } from '@mui/material';
import { ResponseSources } from '@src/pages/dashboard/components/interface/interface';
import { Card, Button } from 'antd';
import React from 'react';






const ResearchSources = ({ sources }: { sources: ResponseSources[] }) => {
  return (
    <div className="grid grid-cols-1 gap-4 pb-4 px-4">
       
      {sources.map((source, index) => (
        <Card key={index} className="hover:shadow-lg m-0 bg-transparent transition-shadow hover:dark:shadow-gray-700 border border-gray-300 dark:border-gray-700 rounded-lg overflow-hidden">
          <CardContent className='m-0'>
            <h2 className="text-lg font-semibold mb-2 dark:text-white">{source.title}</h2>
            <p className="text-sm text-gray-600 dark:text-gray-200 mb-4">Source: {source.provider}</p>
            <Button type='link' className='border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white rounded-md p-2'>
              <a href={source.url} target="_blank" rel="noopener noreferrer" className='dark:text-white'>
                Visit Source
              </a>
            </Button>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default ResearchSources;
