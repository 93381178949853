/* eslint-disable @typescript-eslint/no-unused-vars */
import LoadingComponent from '@src/components/LoadingComponent';
import { ChatType } from '@src/core/interfaces/chat';
import { handleAddHistory } from '@src/pages/dashboard/endpoints';
import { prompts } from '@src/utils/prompts';
import { Checkbox, Form, Input } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { io, Socket } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import MarkdownDesign from '@src/components/Markdown';
import Like from '@public/like.svg';
import DisLike from '@public/dislike.svg';
import Message from '@public/message-circle-2.svg';
import Regenerate from '@assets/repeat.svg';
import Profile from '@assets/user-circle.svg';
import { contentPromptOptionsData2 } from '../constants';
import { openEditor } from '@src/utils/app_functions';
import { useLocation, useNavigate, useOutletContext } from 'react-router';
import { InputTypeProps } from '../../assessment_2/interface';
import { gradeOptions, socialMedias } from '../../curriculum/constants';
import { BASE_URL } from '@src/utils/constants';
import { Chats } from '@src/pages/dashboard/components/interface/interface';
import { useDocumentContext } from '@src/context/DocumentContext';
import { useUserContext } from '@src/context/UseProvider';
import NewEditorPage from '../../essay/editor/newInstanceOfEditor';
import NewSunEditorComponent from '../../essay/sun_editor copy';
import NewContentEditorPage from '../../essay/editor/newInstanceOfEditor copy';
import BackButton from '@src/components/BackButton';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Lesson } from '../components/lesson';
import { ThemeContext } from '@src/theme_provider/theme';

interface UserDocOn {
    documentId: string;
    users: string[];
    admin: any;
    collaborators: any[];
}

const EditorInterface = ({
    response,
    setResponse,
    setCurrentPage,
    setPageNumber,
    setShowSidebar,
    setShowTop,
    pageName,

    setPageName,
    setSubPage,
}: {
    response: string;
    setResponse: any;
    setCurrentPage: any;
    setPageNumber: any;
    setShowSidebar: any;
    setShowTop: any;
    setPageName: any;
    setSubPage: any;
    pageName: string;
}) => {
    const [isTyping, setIsTyping] = useState(false);
    const {isMobile} = useContext(ThemeContext)
    const [description, setDescription] = useState<string>('');
    const [prompt, setPrompt] = useState<string>('');
    const [socket, setSocket] = useState<Socket | null>(null);
    const StreamId = useRef<any>();
    const DocumentId = useRef('');
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });
    const pageHistory = 'contents';
    const [generating, setGenerating] = useState(false);
    const [generate, setGenerate] = useState(false);
    const [navigate, setNavigate] = useState(false);
    const [pageId, setPageId] = useState('');
    const [showRefrence, setShowReference] = useState(false);
    const [streamEndMessage, setStreamEndMessage] = useState<Chats>({ role: 'assistant', content: '' });
    const bottomRef = useRef<HTMLDivElement>(null);
    const [rememberMe, setRememberMe] = useState(false);
    const Document = useRef<any>();
    const [histories, setHistories] = useState<any>([]);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [topic, setTopic] = useState('');
    const [newMessage, setNewMessage] = useState('');
    const [chatList, setChatList] = useState<ChatType[]>([]);
    const currentEntity = useRef<ChatType>();
    const [showEditorButton, setShowEditorButton] = useState(false);
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [page, setPage] = useState<string>('');
    const [pageClick, setPageClick] = useState<() => void>(() => {});
    const historyCloseId = 'historyClose';
    const [canNavigate, setCanNavigate] = useState(false);
    const [docId, setDocId] = useState<string | undefined>(undefined);
    const [editorContent, setEditorContent] = useState<string>('');
    const [humanizedContent, setHumanizedContent] = useState<any>();
    const [translatedContent, setTranslatedContent] = useState<any>();
    const [editorData, setEditorData] = useState('');
    const location = useLocation();
    const { error, loading, fetchDocument, document, documentResponse } = useDocumentContext();
    const { userDetails } = useUserContext();
    const [userOnDoc, setUserOnDoc] = useState<UserDocOn>();
    const [highlightedTexts, setHighlightedTexts] = useState<string>('');
    const [isEditContent, setIsEditContent] = useState(false);
    // const [response, setResponse] = useState('');
    const [latestRespone, setLatestRespone] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const editorInstanceRef = useRef<any>();
    const grades = gradeOptions();
    const [isMobileStep, setIsMobileStep] = useState(0);

    const {
        details,
    } = useOutletContext<any>();

    useEffect(() => {
        if (isTyping && response != '') {
            bottomRef.current?.scrollTo({
                top: bottomRef.current?.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [isTyping, response]);

    const naviagtePage = useNavigate();

    const options = contentPromptOptionsData2({
        response,
        showDownload: () => {},
    });



    useEffect(() => {
        const newSocket = io(BASE_URL.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    const onGenerateHandler = async (message: string, humanize = false) => {
        // console.log(isComprehensive ? prompts.content(description, showRefrence) : prompts.shortContent,);
        // return;

        toast.info('Please sit tight, your beautiful content is on its way.');

        // setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: message };
        // console.log(msgs);
        // return;
        setChats(msgs);
        setResponse('');
        console.log(description);
        setResponse('');

        try {
            socket?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',
                            //   content: `You are G-Mind. You can help with all educational or academic questions or tasks`,
                            content: message,
                            type: 'gpt-4',
                        },
                        msgs,
                    ],
                    userId: details?.userId,
                },
            });
        } catch (error) {
            //   setIsTyping(false);
        } finally {
            //   setIsTyping(false);
        }
    };

    useEffect(() => {
        console.log(`edit content: ${isEditContent}`);
    }, [isEditContent]);

    useEffect(() => {
        if (response) {
            setEditorContent(response);
        }
    }, [response]);

    useEffect(() => {
        if (!socket) return;
        let tempBuffer = '';

        socket.on('data', (data: string) => {
            // setResponse((response: any) => response + data);

            setResponse((response: any) => response + data);
            console.log(data);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            const { streamId, assistant } = data;
            // console.log(`-------------------Testing ooo------------------`);

            // const assistantMessage = assistant[assistant.length - 1].content;
            // // setLatestRespone(assistantMessage);
            // setEditorContent(assistantMessage);
            setIsTyping(false);
            toast.success('Content generated successfully');
            setIsEdit(true);

            const uuid = uuidv4();
            DocumentId.current = uuid;
            setDocId(uuid);
            const user = JSON.parse(localStorage.getItem('user') || '');
            setStreamEndMessage(data.assistant);
            setIsTyping(false);
            setDescription('');
            // if (streamId === StreamId.current) {
            StreamId.current = '';
            socket.emit('get-documents', user?.id);
            setNavigate(true);
            setPageId(uuid);
            // }
        });

        return () => {
            socket.off('message');
        };
    }, [socket, isEditContent]);

    const handleStreamEnd = async (prompt: string, data: Chats) => {
        // let dataToSave: Chats[]  = [
        //     {
        //         role: 'user',
        //         content: prompt,
        //     },
        //     data
        // ]
        let dataToSave = data;

        const promptSent = prompt;

        let docData = {
            content: response,
            doc_id: pageId,
            promptSent: prompt,
            promptType: promptSent,
        };

        await handleAddHistory(dataToSave, pageHistory, docData);
        if (pageId) fetchDocument(pageId ?? '', true);
    };

    useEffect(() => {
        if (!isTyping && response != '') {
            handleStreamEnd(prompt, streamEndMessage);
        }
        // const promptSent = isComprehensive ? prompts.content(description, showRefrence) : prompts.shortContent;
        // if (navigate && response != '') {
        //     openEditor({
        //         response,
        //         pageId,
        //         promptSent: prompt,
        //         promptType: promptSent,
        //         navigate: naviagtePage,
        //         pageSocket: socket,
        //     });
        // }
    }, [response, navigate, isTyping, socket]);

    const onFinish = (values: any) => {
        let promptMessage = '';
        setIsMobileStep(1)
        setIsTyping(true);

        const subject = values.subject;
        const topic = values.topic;
        const objective = values.objective;
        const grade = values.gradeLevel;

        // check if all data is passed
        if (!subject || !topic || !objective) {
            toast.error('Please enter all prompts field');
            return;
        }

        const informationData = `
            Subject: ${subject}
            Topic: ${topic}
            Grade Level: ${grade}
            Objective: ${objective}
        `;
        promptMessage = `
        Please generate an engaging and informative exit ticket for my students. 
        The exit ticket should include the following details: 
        ${informationData}
      
        Format the exit ticket as follows:
        - Title: A clear and concise title for the exit ticket.
        
      
      
        Ensure the tone is friendly and supportive to encourage participation.
      `;
      


        onGenerateHandler(promptMessage, false);
    };

    const onFinish2 = async (value: any) => {
        const { subject, topic, gradeLevel, objective } = value;
        if (!topic) {
            toast.error('Enter your description');
            return;
        }

        setIsMobileStep(1)

        setIsTyping(true);

        const message = lessonPrompt(subject, topic, gradeLevel, objective);
        setPrompt(lessonPrompt(subject, topic, gradeLevel, objective));
        console.log(message);
        console.log(prompt);

        await onGenerateHandler(lessonPrompt(subject, topic, gradeLevel, objective));
        setGenerate(true);
        setGenerating(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };
    const regenerate = async (humanize = false) => {
        let newPrompt = prompt;
        setPrompt(newPrompt);
        await onGenerateHandler(newPrompt, humanize);
        setGenerate(true);
        setGenerating(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };

    return (
        <div className="w-full px-0 md:px-5 pt-24  xl:pt-3  h-screen overflow-y-scroll lg:h-screen xl:h-screen flex flex-col md:flex-row gap-x-10 gap-y-3">
        <div
            style={{ boxShadow: 'rgba(229, 81, 9, 0.6) 0px 19px 38px, rgba(255, 20, 147, 0.5) 0px 15px 12px' }}
            className={`${isMobile ? (isMobileStep === 0 ? 'flex ' : 'hidden') : 'flex'} bg-white  flex-col gap-y-3 h-auto overflow-y-scroll mb-24 md:mb-0  lg:h-[84%] xl:h-[97%] w-full   xl:w-[30%] rounded-[0.5rem] dark:shadow-none px-10 py-5 shadow-lg relative`}
        >
                <div className="flex flex-row gap-x-2 items-center justify-start">
                    <button
                        className="bg-gray-300 h-6 w-6 rounded-full p-2 flex flex-row gap-x-2 items-center justify-center "
                        onClick={() => {
                            setCurrentPage(0);
                            setPageNumber(0);
                            setShowSidebar(true);
                            setShowTop(true);
                            setResponse('');

                            setPageName('');
                            setSubPage('');
                        }}
                    >
                        {' '}
                        <LeftOutlined />{' '}
                    </button>
                    <span>Back</span>
                </div>

                <p className="font-bold  text-[1rem] text-[var(--gmind-light-black)]">{pageName}</p>

               
                {pageName === 'Exit Ticket' ? (
                     <div className="flex flex-col overflow-y-scroll ">
                    <Form onFinish={onFinish} className="flex flex-col py-4  space-y-2" layout="vertical">

                        <div className='lg:grid xl:flex xl:flex-col w-full lg:grid-cols-2 gap-3'>
                        <Form.Item
                            required={true}
                            label={<p className='text-black'>What subject would you like to create a ticket for</p>}
                            name="subject"
                            className="w-full "
                        >
                            
                                <input
                                    // rows={2}
                                    className="p-2  border  border-gray-700 rounded w-full placeholder-gray-700"
                                    placeholder="Enter a subject you would like to create a hook for"
                                    // value={celebrations}
                                    style={{ outline: 'none', boxShadow: 'none' }}
                                />
                            
                        </Form.Item>

                        <Form.Item
                            required={true}
                            label={<p className="text-black">Topic you'll be teaching </p>}
                            name="topic"
                            className="w-full "
                        >
                            <input
                                // rows={2}
                                className="p-2  border  border-gray-700 rounded w-full placeholder-gray-700"
                                placeholder="Enter a topic"
                                style={{ outline: 'none', boxShadow: 'none' }}
                            />
                        </Form.Item>
                        <Form.Item required={true} label={<p className='text-black'>Grade Level</p>} name="gradeLevel" className="w-full">
                          
                          <select className="p-2  border  border-gray-700 rounded w-full placeholder-gray-700">
                              {grades.map((item, index) => (
                                  <option key={index} value={item}>
                                      {item}
                                  </option>
                              ))}
                          </select>
                      
                  </Form.Item>

                        <Form.Item
                            label={<p className="text-black">Learning objectives of your lesson</p>}
                            required={true}
                            name="objective"
                            className="w-full "
                        >
                            <textarea
                                // value={additionalContent}
                                rows={2}
                                className="p-2  border  border-gray-700 rounded w-full placeholder-gray-700"
                                placeholder="Enter learning objective"
                                style={{ outline: 'none', boxShadow: 'none' }}
                            />
                        </Form.Item>
                        </div>

                        <Form.Item>
                            <button
                                type="submit"
                                className="no-hover-effect mt-2 w-full md:w-[8rem] py-2 px-4 bg-customOrange text-white rounded-lg hover:none"
                                disabled={isTyping}
                            >
                                Generate
                            </button>
                        </Form.Item>
                    </Form>
                    </div>
                ) : (
                    <div className="flex flex-col overflow-y-scroll ">
                    <Form onFinish={onFinish2} className="flex flex-col py-4  space-y-3" layout="vertical">
                         <div className='lg:grid xl:flex xl:flex-col w-full lg:grid-cols-2 gap-3'>
                        

                        <Form.Item
                            required={true}
                            label={<p className='text-black'>Subject would you like to create a hook planner for </p>}  
                            name="subject"
                            className="w-full"
                        >
                            
                                <input
                                    className="p-2  border  border-gray-700 rounded w-full placeholder-gray-700"
                                    placeholder="Enter a subject you would like to generate a hook for"
                                    style={{ outline: 'none', boxShadow: 'none' }}
                                />
                        
                        </Form.Item>

                        <Form.Item required={true} label={<p className='text-black'>Topic you'll be teaching </p>} name="topic" className="w-full">
                            <div className="flex items-start border border-gray-700 rounded">
                                <input
                                    className="p-2 w-full focus:outline-none"
                                    placeholder="Enter a topic"
                                    style={{ outline: 'none', boxShadow: 'none' }}
                                />
                            </div>
                        </Form.Item>

                        <Form.Item required={true} label={<p className='text-black'>Grade Level</p>} name="gradeLevel" className="w-full">
                          
                                <select className="p-2  border  border-gray-700 rounded w-full placeholder-gray-700">
                                    {grades.map((item, index) => (
                                        <option key={index} value={item}>
                                            {item}
                                        </option>
                                    ))}
                                </select>
                            
                        </Form.Item>

                        <Form.Item
                            label={<p className='text-black'>Learning objectives of your lesson </p>}
                            required={true}
                            name="objective"
                            className="w-full"
                        >
                            
                                <textarea
                                    rows={2}
                                    className="p-2  border  border-gray-700 rounded w-full placeholder-gray-700"
                                    placeholder="Enter learning objective"
                                    style={{ outline: 'none', boxShadow: 'none' }}
                                />
                          
                        </Form.Item>
                        </div>

                        <Form.Item>
                            <button
                                type="submit"
                                className="no-hover-effect mt-2 w-full md:w-[8rem] py-2 px-4 bg-customOrange text-white rounded-lg"
                                disabled={isTyping}
                            >
                                Generate
                            </button>
                        </Form.Item>
                    </Form>
                    </div>
                )}
            </div>
            <div
                className={` ${isMobile ? (isMobileStep === 1 ? 'flex mb-20' : 'hidden') : 'flex'} w-full xl:w-[70%] md:w-auto overflow-y-scroll  xl:h-[97%]  flex-col gap-y-3 `}
                style={{ boxShadow: 'rgba(229, 81, 9, 0.6) 0px 19px 38px, rgba(255, 20, 147, 0.5) 0px 15px 12px' }}
            >
                {/* {
                        !generate ? (
                            <div className="mt-5 h-full flex-col flex items-center justify-between">
                                <div className="flex flex-col gap-y-3">
                                    <p className="text-center font-medium text-[1.5rem] dark:text-white text-[var(--gmind-black)]">Create Content</p>
                                    <p className="text-center text-gray-400 font-normal">Generate content for course</p>
                                </div>

                                <p className="mb-10 text-center text-[1rem] text-gray-400 font-normal">Enter content description & click Generate to get started</p>
                            </div>
                        ):(
                            <div>
                                {generating ? 
                                    (
                                        <LoadingComponent isMobile={false} width={''} />
                                    ) : (
                                        <MarkdownDesign className="dark:!text-white">{response}</MarkdownDesign>
                                    )
                                }

                            </div>
                        )
                    } */}

                <NewEditorPage
                docId={docId}
                setDocId={setDocId}
                setIsMobileStep={setIsMobileStep}
                    isContent={true}
                    regenerate={regenerate}
                    isBack={isMobile ? false:true}
                    latestRespone={latestRespone}
                    setLatestRespone={setLatestRespone}
                    highlightedTexts={highlightedTexts}
                    setHighlightedTexts={setHighlightedTexts}
                    content={
                        <NewSunEditorComponent
                            latestRespone={latestRespone}
                            setLatestRespone={setLatestRespone}
                            highlightedTexts={highlightedTexts}
                            setHighlightedTexts={setHighlightedTexts}
                            isContent={ true}
                            editorInstanceRef={editorInstanceRef}
                            // humanizeded_content={Document.current?.data.ops}
                            // hasAccess={hasAccess}
                            hasAccess={(documentResponse?.role ?? '') !== 'view' ? true : false && !isTyping}
                            initial_content={response}
                            onChangeEditorContent={(e: string) => {
                                setEditorContent(e);

                                const newtext = e;
                                // // console.log('newtext', newtext);
                                // console.log('docId', docId);
                                // console.log('userDetails?.userId', userDetails?.userId);
                                // sendChanges(docId, userDetails?.userId, e);
                            }}
                            contentHeight={true}
                            // setHighlightedTexts={setHighlightedTexts}
                            // highlightedTexts={highlightedTexts}
                            Response={response}
                            setResponse={setResponse}
                            setIsEditContent={(e) => {
                                setIsEditContent(e);
                            }}
                            contentIsTyping={isTyping}
                            editorContent={editorContent}
                            setEditorContent={setEditorContent}
                        />
                    }
                    showTopNavBar={false}
                    showInvite={documentResponse?.owner ?? false}
                    isTyping={isTyping}
                    Profile={Profile}
                    setGenerate={setGenerate}
                    generate={generate}
                    setEditorContent={setEditorContent}
                    editorContent={editorContent}
                    translatedContent={translatedContent}
                    setTranslatedContent={setTranslatedContent}
                    humanizedContent={humanizedContent}
                    setHumanizedContent={setHumanizedContent}
                    setUserOnDoc={setUserOnDoc}
                    userOnDoc={userOnDoc}
                    editorInstanceRef={editorInstanceRef}
                />

                {/* <div id="controls" className="w-full flex items-center justify-between">
                    <div className="flex gap-x-5 w-[50%] justify-start">
                        <div role="button" onClick={() => regenerate(true)} className="flex items-center">
                            <img src={Profile} alt="" />
                            <p>Humanize</p>
                        </div>
                        {options.map((option, index) => (
                            <div
                                key={index}
                                onClick={option.onClick}
                                className="rounded-[1rem] px-[1rem] py-[0.3rem] bg-[var(--gmind-header)] flex gap-x-3 cursor-pointer"
                            >
                                <img className='w-[1rem] h-[1rem]' src={option.icon} alt="" />
                                <div className="font-normal text-[0.75rem] text-[var(--gmind-plain-black)]">
                                    {option.name}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="flex gap-x-5 w-[50%] justify-end">
                        <div
                            className="hidden md:flex items-center flex-row"
                            style={{ cursor: 'pointer' }}
                            onClick={() => regenerate()}
                        >
                            <img src={Regenerate} alt="" />{' '}
                            <span
                                className="font-normal text-[0.75rem] text-[var(--gmind-plain-black)]"
                                style={{ marginLeft: '2px' }}
                            >
                                Regenerate
                            </span>
                        </div>
                        <img style={{ cursor: 'pointer' }} onClick={() => {}} src={Message} alt="" />
                        <img style={{ cursor: 'pointer' }} onClick={() => {}} src={Like} alt="" />
                        <img
                            style={{ cursor: 'pointer', marginRight: '20px' }}
                            onClick={()=> {}}
                            src={DisLike}
                            alt=""
                        />
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default EditorInterface;

const generateLetterPrompt = (description: string, letterType: string, tone: string, wordCount: number): string => {
    return `
    Generate a letter based on the following specifications:
    
    **Description**: "${description}"
    **Letter Type**: "${letterType}"
    **Tone**: "${tone}"
    **Word Count**: Must be approximately ${wordCount} words.
    
    **Guidelines**:
    - The letter should match the specified type (e.g., formal, informal, business, or personal).
    - Use the appropriate tone throughout the letter (e.g., persuasive, friendly, empathetic, neutral, or formal).
    - The content should be concise, clear, and engaging.
    - Do not include any references or citations.
    - Avoid using unnecessary fluff; ensure the letter achieves its purpose.

    **Formatting**:
    - Include a proper salutation (e.g., "Dear [Recipient's Name],").
    - Provide a clear introduction, body, and closing.
    - Include an appropriate closing phrase (e.g., "Sincerely," or "Best regards,").
    - Do not use any HTML tags or special formatting.

    Generate only the letter content; do not include any metadata, headers, or additional explanations.
    `;
};

const lessonPrompt = (subject: string, gradeLevel: string, topic: string, objective: string): string => {
 const text =  `
Generate a compelling lesson hook for ${subject} tailored to ${gradeLevel} students. 
The hook should introduce the topic '${topic}' in an engaging and relatable way. 
Ensure the hook aligns with the following learning objectives: ${objective}. 
Use a tone and examples appropriate for the specified grade level, and focus on sparking curiosity and engagement.
`;
 return text;

};
