
import { useCallback, useEffect, useRef, useState } from 'react';
import {
    Chats,
    ChatType,
    handleAddHistory,
    // handleDeleteAllWithTypeHistory,
    // handleDeleteHistory,
} from '../../endpoints';
import NewGenerateLetter from './components/new_generate_home';
import { toast } from 'react-toastify';
import { prompts } from '@src/utils/prompts';
import { io, Socket } from 'socket.io-client';
import {
    deleteQueryParam,
    getQueryParams,
    openCurriculumEditor,
    updateQueryParam,
} from '@src/utils/app_functions';

import Profile from '@assets/Avatar.svg';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useOutletContext } from 'react-router';
import { BASE_URL } from '@src/utils/constants';
import { HistoryTypeFormat } from '@src/core/interfaces/chat';
import { useDocumentContext } from '@src/context/DocumentContext';
import { useUserContext } from '@src/context/UseProvider';
import { useDebouncer } from '../../function/helper';
import NewEditorPage from '../essay/editor/newInstanceOfEditor';
import NewSunEditorComponent from '../essay/sun_editor copy';
import LoginPromptModal from '../essay/editor/components/modal';

interface UserDocOn {
    documentId: string;
    users: string[];
    admin: any;
    collaborators: any[];
}

const BusinessResources = () => {
    const pageHistory = 'business-resources';
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(true);
    const [histories, setHistories] = useState<any[]>([]);
    const [page, setPage] = useState<string>('');
    const [generating, setGenerating] = useState(false);
    // const [response, setResponse] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [generate, setGenerate] = useState(false);
    const [sideBarVisible, setSidebarVisible] = useState<boolean | null>(null);
    const [showEditorButton, setShowEditorButton] = useState(false);
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });
    const [promptSent, setPromptSent] = useState('');
    const [newPromptSent, setNewPromptSent] = useState('');
    const [socket, setSocket] = useState<Socket | null>(null);
    const [message, setMessage] = useState('');
    const StreamId = useRef<any>();
    const DocumentId = useRef('');
    const [canNavigate, setCanNavigate] = useState(false);
    const [topic, setTopic] = useState('');
    const [basePrompt, setBasePrompt] = useState('');  const [docId, setDocId] = useState<string | undefined>(undefined);
    const [showingHistory, setShowingHistory] = useState(false);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [historyType, setHistoryType] = useState<string>('content');
    const [conversation, setConversation] = useState<ChatType[]>([]);
    const [streamEndMessage, setStreamEndMessage] = useState<ChatType>({ role: 'assistant', content: '' });

    const [editorContent, setEditorContent] = useState<any>('');
    const [humanizedContent, setHumanizedContent] = useState<any>();
    const [translatedContent, setTranslatedContent] = useState<any>();
    const { documentResponse } = useDocumentContext();
    const { userDetails } = useUserContext();
    const [userOnDoc, setUserOnDoc] = useState<UserDocOn>();
    const editorInstanceRef = useRef<any>();
    const [highlightedTexts, setHighlightedTexts] = useState<string>('');
    const [latestRespone, setLatestRespone] = useState<string>('');
    const {
        setShowTop,
        setShowSidebar,
        setHistory,
        setMobileNavStyle,
        setShowPagePath,
        setCanNavigateHome,
        setShowInput,
        setShowHistoryOptions,
        setShowRightComponent,
        setShowHistory,
        response,
        setResponse,
        getHistory,
        details,
    } = useOutletContext<any>();
    const [isNotLoggedIn, setIsNotLoggedIn] = useState(false);


    const bottomRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (isTyping && response != '') {
            console.log('scrolling');
            bottomRef.current?.scrollTo({
                top: bottomRef.current?.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [isTyping, response]);
    const getPageHistory = useCallback(async () => {
        getHistory(pageHistory).then((response: any) => {
            if (response?.statusCode === 1) {
                const data: any[] = response?.data;
                const filtered: HistoryTypeFormat[] = data
                    ?.map((d: any) => {
                        return {
                            date: d.date,
                            histories: d.histories
                                ?.map((history: any) => {
                                    if (history.type == historyType) {
                                        return [
                                            ...history.conversation,
                                            { id: history._id },
                                            { type: history.type },
                                            { date: history.updatedAt },
                                        ];
                                    } else {
                                        return [];
                                    }
                                })
                                .filter((element: any) => element.length > 0),
                        };
                    })
                    .filter((element: any) => element.histories.length > 0);
                console.log(filtered);
                setHistories(filtered);
            } else {
                setHistories([]);
            }
        });
    }, [pageHistory, historyType, setHistories]);
    useEffect(() => {
        getPageHistory();
    }, [getPageHistory]);


    useEffect(() => {
        const newSocket = io(BASE_URL.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    const sendChanges = useDebouncer((documentId: string, userId: string, data: string) => {
        console.log('sendChanges', documentId, userId, data);
        socket?.emit('send-changes', {
            documentId,
            userId,
            data,
        });
    }, 1000);

    const removeQueryParameter = (key: string) => {
        const url = new URL(window.location.href);
        url.searchParams.delete(key); // Remove the query parameter
        window.history.pushState({}, '', url.toString()); // Update the URL without navigation
    };

    function getDocIdFromUrl() {
        try {
            const urlObject = new URL(window.location.href); // Parse the URL
            const doc = urlObject.searchParams.get('document');
            setDocId(doc ?? '');
        } catch (error) {
            console.error('Invalid URL', error);
            return null;
        }
    }

    useEffect(() => {
        console.log(generate);

        if (generate || showingHistory) {
            openCurriculumEditor({
                response,
                pageId: docId,
                promptSent,
                promptType: prompts.curriculum,
                navigate,
                pageSocket: socket,
            });
            getDocIdFromUrl();
        } else {
            removeQueryParameter('document');
        }
    }, [generate, showingHistory]);

    // DataInsight = "dataInsight",
    // CopyWriting = "copyWriting",
    // CampaignAd = "campaignAd",
    // PolicyWriter = "policyWriter",
    // JobDescription = "jobDescription",
    // ProjectReport = "projectReport",
    // Transcriber = "transcriber",
    // TextTranslator = "textTranslator",
    // TextSummarizer = "textSummarizer",

    useEffect(() => {
        const paramsKey = 'type';
        if (currentPage == 1) {
            updateQueryParam(paramsKey, 'copyWriting');
        } else if (currentPage == 2) {
            updateQueryParam(paramsKey, 'campaignAd');
        } else if (currentPage == 3) {
            updateQueryParam(paramsKey, 'policyWriter');
        } else if (currentPage == 4) {
            updateQueryParam(paramsKey, 'jobDescription');
        } else if (currentPage == 5) {
            updateQueryParam(paramsKey, 'projectReport');
        } else if (currentPage == 6) {
            updateQueryParam(paramsKey, 'transcriber');
        } else if (currentPage == 8) {
            updateQueryParam(paramsKey, 'textTranslator');
        } else if (currentPage == 9) {
            updateQueryParam(paramsKey, 'textSummarizer');
        } else if (currentPage == 10) {
            updateQueryParam(paramsKey, 'dataInsight');
        } else if (currentPage == 0) {
            deleteQueryParam(paramsKey);
        }

        getPageHistory();
    }, [currentPage]);

    useEffect(() => {
        if (conversation.length == 0) return;
        if (conversation.length > 0 && !isTyping) {
            handleStreamEnd(promptSent, conversation);
        }
        handleAddHistory(conversation, pageHistory, historyType);
        getPageHistory();
        setConversation([]);
    }, [conversation, historyType, isTyping]);

    useEffect(() => {
        if (!socket) return;

        socket.on('data', (data: string) => {
            setResponse((response: string) => response + data);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            const { streamId, assistant } = data;
            setStreamEndMessage(assistant);
            setShowEditorButton(true);
            setIsTyping(false);
            setCanNavigate(true);
            setConversation([assistant]);
            if (streamId === StreamId.current) {
                StreamId.current = '';
                // setStreaming(false);
                const uuid = uuidv4();
                setDocId(uuid);
                DocumentId.current = uuid;
                const user = JSON.parse(localStorage.getItem('user') || '');
                try {
                    socket?.emit('store-document', {
                        id: uuid,
                        title: promptSent,
                        value: assistant.content,
                        owner_id: user?.id,
                    });
                } catch (error) {
                    socket?.emit('store-document', {
                        id: uuid,
                        title: promptSent,
                        value: assistant.content,
                    });
                }
                socket.emit('get-documents', user?.id);
            }
        });

        socket.on('user_log_in', (data: string) => {
            console.log(data);
            setIsNotLoggedIn(true);
        });

        return () => {
            socket.off('message');
        };
    }, [socket]);

    const handleStreamEnd = async (prompt: string, data: ChatType[]) => {
        const dataToSave: ChatType[] = data;

        const docData = {
            content: response,
            doc_id: docId,
            promptSent: prompt,
            promptType: promptSent,
        };

        const fullUrl = getQueryParams() !== '' ? `${pageHistory}/${getQueryParams()}` : pageHistory;

        await handleAddHistory(dataToSave, fullUrl, docData);
        getPageHistory();
    };



    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    const regenerate = () => {
        onGenerateHandler(promptSent);
        setGenerate(true);
        setGenerating(true);
        setIsTyping(true);
    };

    const onFinish = async (promptMessage: string) => {
        console.log(promptMessage);
        setMessage(promptMessage);
        console.log(message);
        setIsTyping(true);
        setPromptSent(promptMessage);
        await onGenerateHandler(promptMessage);
        setGenerate(true);
        setGenerating(true);
    };



    const onGenerateHandler = async (message: string) => {
        if (!message) {
            toast.error("Input can't be empty");
            return;
        }
        toast.info('Please sit tight, your beautiful content is on its way.');
        // setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: message };
        // console.log(msgs);
        // return;
        setChats(msgs);
        setNewPromptSent('');
        setResponse('');

        console.log(msgs);

        // let base = promptSelected ? promptSelected : letter ? prompts.letterWritter : prompts.content2(topic);
        // setBasePrompt(base);

        try {
            socket?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',
                            //   content: `You are G-Mind. You can help with all educational or academic questions or tasks`,
                            content: getPromptTypeBasedOnPage(),
                            type: 'gpt-4',
                        },
                        msgs,
                    ],
                    userId: details?.userId,
                },
            });
        } catch (error) {
            //   setIsTyping(false);
        } finally {
            //   setIsTyping(false);
        }
    };

    // const handleDeleteContentHistory = useCallback(
    //     async (id: string) => {
    //         await handleDeleteHistory(id, pageHistory);
    //         await getPageHistory();
    //         toast.success('Chat deleted successfully');
    //     },
    //     [getPageHistory],
    // );

    // const clearAllContentHistory = useCallback(async () => {
    //     await handleDeleteAllWithTypeHistory(pageHistory, historyType);
    //     await getPageHistory();
    //     toast.success('Chat cleared successfully');
    // }, [getPageHistory, historyType]);

    const getPromptTypeBasedOnPage = () => {
        let prompt = prompts.chat;

        if (currentPage == 1) {
            prompt = prompts.copyWritingTool;
        } else if (currentPage == 2) {
            prompt = prompts.campaignAdGenerator;
        } else if (currentPage == 3) {
            prompt = prompts.policyWriter;
        } else if (currentPage == 4) {
            prompt = prompts.jobDescriptionGenerator;
        } else if (currentPage == 5) {
            prompt = prompts.projectReportGenerator;
        } else if (currentPage == 7) {
            prompt = prompts.socialContent;
        } else if (currentPage == 8) {
            prompt = prompts.textTranslator;
        } else if (currentPage == 9) {
            prompt = prompts.textSummarizer;
        }

        return prompt;
    };

    useEffect(() => {
        setHistory(histories);
        setShowRightComponent(false);

        setShowTop(true);
        setShowSidebar(true);
        setMobileNavStyle(!isMobile ? { position: 'fixed' } : {});

        setShowHistory(!isMobile);
        setShowInput(false);
        setShowPagePath(false);
        setCanNavigateHome(true);
        setShowHistoryOptions(page != '');
    }, [isMobile, page]);

    useEffect(() => {
        if (generate || showingHistory) {
            setShowTop(false);
            setShowSidebar(false);
        } else {
            setShowTop(true);
            setShowSidebar(true);
        }
    }, [generate, showingHistory]);

    useEffect(() => {
        if (response) {
            setEditorContent(response);
        }
    }, [response]);

    return (
        <div ref={bottomRef} className=" h-full md:h-screen overflow-y-scroll ">
            {!generate && !showingHistory ? (
                <NewGenerateLetter
                    onGenerate={(prompt) => {
                        onFinish(prompt);
                    }}
                    setTopic={(e) => setTopic(e)}
                    setPageName={(e) => setPage(e)}
                    setShowSidebar={(e) => setSidebarVisible(e)}
                    setPageNumber={(i) => {
                        setCurrentPage(i);
                    }}
                />
            ) : (
                <div className="w-full flex flex-col gap-0  bg-white dark:bg-[#212121]">
                    <NewEditorPage
                        docId={docId}
                        setDocId={setDocId}
                        editorInstanceRef={editorInstanceRef}
                        regenerate={regenerate}
                        content={
                            <NewSunEditorComponent
                                latestRespone={latestRespone}
                                setLatestRespone={setLatestRespone}
                                highlightedTexts={highlightedTexts}
                                setHighlightedTexts={setHighlightedTexts}
                                editorInstanceRef={editorInstanceRef}
                                // humanizeded_content={Document.current?.data.ops}
                                // hasAccess={hasAccess}
                                hasAccess={!isTyping && (documentResponse?.role ?? '') !== 'view' ? true : false}
                                initial_content={response}
                                onChangeEditorContent={(e: string) => {
                                    setEditorContent(e);
                                    const newtext = e;
                                    console.log('newtext', newtext);

                                    console.log('docId', docId);
                                    console.log('userDetails?.userId', userDetails?.userId);

                                    sendChanges(docId, userDetails?.userId, e);
                                }}
                            />
                        }
                        showTopNavBar={false}
                        showInvite={true}
                        isTyping={isTyping}
                        Profile={Profile}
                        promptSent={promptSent}
                        showingHistory={showingHistory}
                        setGenerate={setGenerate}
                        setShowingHistory={setShowingHistory}
                        generate={generate}
                        setEditorContent={setEditorContent}
                        editorContent={editorContent}
                        translatedContent={translatedContent}
                        setTranslatedContent={setTranslatedContent}
                        humanizedContent={humanizedContent}
                        setHumanizedContent={setHumanizedContent}
                        setUserOnDoc={setUserOnDoc}
                        userOnDoc={userOnDoc}
                        highlightedTexts={highlightedTexts}
                        setHighlightedTexts={setHighlightedTexts}
                        latestRespone={latestRespone}
                        setLatestRespone={setHighlightedTexts}
                    />
                </div>
            )}

            {isNotLoggedIn && <LoginPromptModal isNotSignedIn visible={isNotLoggedIn} />}

        </div>
    );
};

export default BusinessResources;
