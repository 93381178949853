import React, { useState } from "react";

export const Review = ({
  contents,
  setContent,
}: {
  contents: string;
  setContent: React.Dispatch<React.SetStateAction<string>>;
}) => {
  // Parse the contents into an array of lines
  const [contentLines, setContentLines] = useState(
    contents.split(/\d+\.\d* /).filter(Boolean)
  );

  // Track which item is being edited
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  // Update the main contents after every change
  const updateContents = (lines: string[]) => {
    setContentLines(lines);
    setContent(lines.map((line, i) => `${i + 1}. ${line}`).join("\n"));
  };

  // Handle moving items up or down
  const moveItem = (fromIndex: number, toIndex: number) => {
    if (toIndex < 0 || toIndex >= contentLines.length) return; // Prevent invalid moves
    const updatedLines = [...contentLines];
    const [movedItem] = updatedLines.splice(fromIndex, 1); // Remove the item
    updatedLines.splice(toIndex, 0, movedItem); // Insert it in the new position
    updateContents(updatedLines);
  };

  // Handle adding a new item
  const addItem = () => {
    const updatedLines = [...contentLines, "New Item"];
    updateContents(updatedLines);
  };

  // Handle editing an item
  const editItem = (index: number, newValue: string) => {
    const updatedLines = [...contentLines];
    updatedLines[index] = newValue;
    updateContents(updatedLines);
  };

  const handleDelete = (index: number) => {
    const updatedLines = contentLines.filter((_, i) => i !== index);
    updateContents(updatedLines);
  };

  return (
    <div>
      <h2 className="text-xl font-bold mb-4 dark:text-gray-300">
        Review & Edit Your Table of Contents
      </h2>
      <div className="mt-4 flex flex-col space-y-2">
        {contentLines.map((line, index) => (
          <div
            key={index}
            className="bg-white rounded shadow p-2 w-full md:w-[70%] flex items-center space-x-4"
          >
            <span>{index + 1}.</span>
            {editingIndex === index ? (
              <input
                type="text"
                value={line}
                onChange={(e) => editItem(index, e.target.value)}
                onBlur={() => setEditingIndex(null)} // Exit edit mode when focus is lost
                className="flex-1 p-1 border rounded focus:outline-none focus:ring focus:ring-blue-300"
                autoFocus
              />
            ) : (
              <span className="flex-1">{line}</span>
            )}
            <div className="space-x-2">
              {editingIndex === index ? (
                <button
                  onClick={() => setEditingIndex(null)}
                  className="bg-gray-500 text-white px-2 py-1 rounded "
                >
                  Done
                </button>
              ) : (
                <button
                  onClick={() => setEditingIndex(index)}
                  className="bg-customOrange text-white px-2 py-1 rounded "
                >
                  Edit
                </button>
              )}
              <button
                onClick={() => handleDelete(index)}
                className="bg-customOrange text-white px-2 py-1 rounded "
                disabled={index === 0} // Disable moving the first item up
              >
                Delete
              </button>
              <button
                onClick={() => moveItem(index, index - 1)}
                className="bg-customOrange text-white px-2 py-1 rounded "
                disabled={index === 0} // Disable moving the first item up
              >
                Up
              </button>

              
              <button
                onClick={() => moveItem(index, index + 1)}
                className="bg-customOrange text-white px-2 py-1 rounded hover:bg-blue-600"
                disabled={index === contentLines.length - 1} // Disable moving the last item down
              >
                Down
              </button>
            </div>
          </div>
        ))}
      </div>
      <button
        onClick={addItem}
        className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
      >
        Add Item
      </button>
    </div>
  );
};
