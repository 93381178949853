export const plans = [
    {
        title: "G-Free",
        monthlyPrice: 0,
        quarterlyPrice: 0,
        yearlyPrice: 0,
        yearlyPriceNgn: 0,
        quarterlyPriceNgn: 0,
        monthlyPriceNgn: 0,
        monthlySavePercent: 0,
        quarterlySavePercent: 0,
        yearlySavePercent: 0,
        description: "Explore how GMIND AI can help you with everyday tasks",
        features: [
            {
                text: "Infinite Chatting",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "E-mail support",
                includedMonthly: true,
                includedQuarterly: false,
                includedYearly: false,
            },
            {
                text: "Text Editing",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Social Media Content Generator",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Assessment Generator",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Short Content Generator",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Comprehensive Content Generator",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Learning Material Generator",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Learning & Development Portal access",
                includedMonthly: true,
                includedQuarterly: false,
                includedYearly: false,
            },
            {
                text: "Downloadable Content",
                includedMonthly: false,
                includedQuarterly: false,
                includedYearly: false,
            },
            {
                text: "Image Generation-5 images monthly",
                includedMonthly: false,
                includedQuarterly: false,
                includedYearly: false,
            },
            {
                text: "Slide Maker",
                includedMonthly: false,
                includedQuarterly: false,
                includedYearly: false,
            },
            {
                text: "Invite team",
                includedMonthly: false,
                includedQuarterly: false,
                includedYearly: false,
            },
            {
                text: "Content translator",
                includedMonthly: false,
                includedQuarterly: false,
                includedYearly: false,
            },
            {
                text: "Article writer",
                includedMonthly: false,
                includedQuarterly: false,
                includedYearly: false,
            },
            {
                text: "Letter writer",
                includedMonthly: false,
                includedQuarterly: false,
                includedYearly: false,
            },
            {
                text: "Save to Gmind Drive",
                includedMonthly: false,
                includedQuarterly: false,
                includedYearly: false,
            },
            {
                text: "Humanizer",
                includedMonthly: false,
                includedQuarterly: false,
                includedYearly: false,
            },
            {
                text: "Speech to Text",
                includedMonthly: false,
                includedQuarterly: false,
                includedYearly: false,
            },
        ],
        actionLabel: "Enjoy Gmind for Free",
    },
    {
        title: "G-Basic",
        monthlyPrice: 6,
        quarterlyPrice: 18,
        yearlyPrice: 72,
        yearlyPriceNgn: 65294,
        quarterlyPriceNgn: 15000,
        monthlyPriceNgn: 5000,
        monthlySavePercent: 50,
        quarterlySavePercent: 50,
        yearlySavePercent: 50,
        description: "Upgrade today for amazing exclusive offers.",
        features: [
            {
                text: "Infinite Chatting",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Text Editing",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Image Generation-5 images monthly",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Social Media Content Generator",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Assessment Generator",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Short Content Generator",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Comprehensive Content Generator",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Learning Material Generator",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Downloadable Content",
                includedMonthly: true,
                includedQuarterly: false,
                includedYearly: false,
            },
            {
                text: "Learning & Development Portal access",
                includedMonthly: true,
                includedQuarterly: false,
                includedYearly: false,
            },
            {
                text: "E-mail support",
                includedMonthly: true,
                includedQuarterly: false,
                includedYearly: false,
            },
            {
                text: "Slide Maker",
                includedMonthly: false,
                includedQuarterly: false,
                includedYearly: false,
            },
            {
                text: "Invite team",
                includedMonthly: false,
                includedQuarterly: false,
                includedYearly: false,
            },
            {
                text: "Content translator",
                includedMonthly: false,
                includedQuarterly: false,
                includedYearly: false,
            },
            {
                text: "Article writer",
                includedMonthly: false,
                includedQuarterly: false,
                includedYearly: false,
            },
            {
                text: "Letter writer",
                includedMonthly: false,
                includedQuarterly: false,
                includedYearly: false,
            },
            {
                text: "Save to Gmind Drive",
                includedMonthly: false,
                includedQuarterly: false,
                includedYearly: false,
            },
            {
                text: "Humanizer",
                includedMonthly: false,
                includedQuarterly: false,
                includedYearly: false,
            },
            {
                text: "Speech to Text",
                includedMonthly: false,
                includedQuarterly: false,
                includedYearly: false,
            },
        ],
        actionLabel: "Choose Plan",
    },
    {
        title: "G-Premium",
        monthlyPrice: 10,
        quarterlyPrice: 28,
        yearlyPrice: 102,
        yearlyPriceNgn: 90000,
        quarterlyPriceNgn: 22500,
        monthlyPriceNgn: 7500,
        monthlySavePercent: 50,
        quarterlySavePercent: 50,
        yearlySavePercent: 50,
        description: "Upgrade today for amazing exclusive offers.",
        features: [
            {
                text: "Infinite Chatting",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Text Editing",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Image Generation-5 images monthly",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Social Media Content Generator",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Assessment Generator",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Short Content Generator",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Comprehensive Content Generator",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Learning Material Generator",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Downloadable Content",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Learning & Development Portal access",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "E-mail support",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Slide Maker",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Invite team",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Content translator",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Article writer",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Letter writer",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Save to Gmind Drive",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Humanizer",
                includedMonthly: false,
                includedQuarterly: false,
                includedYearly: false,
            },
            {
                text: "Speech to Text",
                includedMonthly: false,
                includedQuarterly: false,
                includedYearly: false,
            },
        ],
        actionLabel: "Choose Plan",
        popular: true,
    },
    {
        title: "G-Advance",
        monthlyPrice: 15,
        quarterlyPrice: 42,
        yearlyPrice: 153,
        yearlyPriceNgn: 120000,
        quarterlyPriceNgn: 30000,
        monthlyPriceNgn: 10000,
        monthlySavePercent: 50,
        quarterlySavePercent: 50,
        yearlySavePercent: 50,
        description: "Upgrade today for amazing exclusive offers.",
        features: [
            {
                text: "Infinite Chatting",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Text Editing",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Image Generation-5 images monthly",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Social Media Content Generator",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Assessment Generator",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Short Content Generator",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Comprehensive Content Generator",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Learning Material Generator",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Downloadable Content",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Learning & Development Portal access",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "E-mail support",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Slide Maker",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Invite team",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Content translator",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Article writer",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Letter writer",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Save to Gmind Drive",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Humanizer",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
            {
                text: "Speech to Text",
                includedMonthly: true,
                includedQuarterly: true,
                includedYearly: true,
            },
        ],
        actionLabel: "Choose Plan",
    },
];
