/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import History from '@src/pages/dashboard/components/history';
import Sidebar from './Sidebar';
import TopNavBar from './Top';
import { ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import RightComponent from '@src/pages/dashboard/components/rightComponent';
import MobileNavbar from '@src/pages/dashboard/components/mobileNavBar';
import HistoryModal from '@src/pages/dashboard/components/historyModal';
import ArticleModal from '@src/pages/dashboard/components/ArticleModal';
import { GetUser } from '@src/core/endpoints/user';
import { PlanDetails, UserDetailsData } from '@src/core/interfaces/user';
import { plans } from '@src/pages/acquisition/pricing/constants';
import { getUrlPath, logOutUser } from '@src/utils/app_functions';
import { checkIfTimeIsWithinRange, onTrialReminder } from '@src/pages/dashboard/pages/essay/functions';
import { HistoryTypeFormat } from '@src/core/interfaces/chat';
import ExpiredPackageModal from './expired_plan';
import { toast, ToastContainer } from 'react-toastify';
import SchoolHeader from '@src/pages/dashboard/pages/school_admin/components/header';
import CompleteProfile from './complete_profile';
import AffiliateConfirmationModal from './confirm_affilliate';
import page from '@src/pages/dashboard/image';
import AffiliatePendingModal from './confirm_affilliate/second_index';
import MobileSidebar from './Sidebar/index copy';
import { getAllHistory } from './Sidebar/components/getAllHistory';
import { handleDeleteHistory, handleDeleteAllWithTypeHistory } from '@src/pages/dashboard/endpoints';
import { useDocumentContext } from '@src/context/DocumentContext';
import path from 'path';
import { ThemeContext } from '@src/theme_provider/theme';
import { Chats } from '@src/pages/dashboard/components/interface/interface';
import { EditOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { Helmet } from 'react-helmet-async';
import { dashboardData } from '@src/pages/acquisition/strutured_data/structured_data';
import FreeExpiredPackageModal from './expired_plan/free_trial_modal';
import { acquisitionRoutes, chatRoutePages, chatRoutes } from '@src/utils/app_routes';
import LoginPromptModal from '@src/pages/dashboard/pages/essay/editor/components/modal';
// import FreeExpiredPackageModal from './expired_plan/index copy';

type ChatType = {
    role: 'assistant' | 'user';
    content: string;
    id: string;
};

type DataType<T> = {
    title: T;
    content: T;
    id: T;
};

const DashboardFrame = () => {
    const [sidebarCollapse, setSidebarCollapse] = useState(false);
    const [historyCollapse, setHistoryCollapse] = useState<boolean>(false);
    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const [historyHidden, setHistoryHidden] = useState(false);
    const [selected, setSelected] = useState<string | undefined>();
    const [showMobileElements, setShowMobileElements] = useState({ showSidebarMobile: false, showHistory: false });

    const [userPlan, setUserPlan] = useState<PlanDetails | undefined>(undefined);
    const [PathName, setPathName] = useState(window.location.pathname);
    const [packageName, setPackageName] = useState<string | undefined>('');
    const [isExpiredVisible, setIsExpiredVisible] = useState<boolean>(false);
    const [isAffiliateModalVisible, setIsAffiliateModalVisible] = useState<boolean>(false);
    const [isAffiliateModalVisible2, setIsAffiliateModalVisible2] = useState<boolean>(false);
    const [completedProfileVisible, setCompletedProfileVisible] = useState<boolean>(false);
    const [profilePic, setProfilePic] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [details, setDetails] = useState<UserDetailsData | undefined>(undefined);
    const [schoolAdminPageName, setSchoolAdminPageName] = useState('');
    const [showHistory, setShowHistory] = useState(true);
    const [showMobileHistory, setShowMobileHistory] = useState(true);
    const [showTop, setShowTop] = useState<boolean | undefined>(undefined);

    const [showRightComponent, setShowRightComponent] = useState(false);
    const [rightComponentName, setRightComponentName] = useState<string | undefined>(undefined);
    const [rightComponentPlaceholder, setRightComponentPlaceholder] = useState<string | undefined>(undefined);
    const [rightComponentData, setRightComponentData] = useState<any>(null); // Update this to your actual type
    const [history, setHistory] = useState<any[]>([]); // Replace with your actual history data type
    const [middleClass, setMiddleClass] = useState<string | undefined>(undefined);
    const [mobileNavStyle, setMobileNavStyle] = useState<React.CSSProperties | undefined>(undefined);

    const [topExtraData, setTopExtraData] = useState<any>(null); // Update with actual type
    const [closeIdValue, setCloseIdValue] = useState<string | undefined>(undefined);
    const [showHistoryOption, setShowHistoryOption] = useState(true);
    const [historyVisible, setHistoryVisible] = useState(false);
    const [showPagePath, setShowPagePath] = useState(true);
    const [gmindTyping, setGmindTyping] = useState(false);
    const [canNavigateHome, setCanNavigateHome] = useState(true);
    const [autoScroll, setAutoScroll] = useState(false);
    const [showExpiredModal, setShowExpiredModal] = useState(true);
    const [showCompleteProfileModal, setShowCompleteProfileModal] = useState(true);
    const [value, setValue] = useState('');
    const [isAffiliate, setIsAffiliate] = useState(false);
    const [menu, setMenu] = useState<React.ReactElement | undefined>(undefined);
    const [showInput, setShowInput] = useState(true);
    const [showSchoolHeader, setShowSchoolHeader] = useState(false);
    const [selectedHistory, setSelectedHistory] = useState<(v: string) => void>(() => {});
    const [onClickPage, setOnClickPage] = useState<(callback?: () => void) => void>(() => () => {});
    const [onDeleteHistory, setOnDeleteHistory] = useState<(id: string) => void>(() => () => {});
    const [onDeleteAllHistory, setOnDeleteAllHistory] = useState<() => void>(() => () => {});
    const [onDetailsFetched, setOnDetailsFetched] = useState<(e: UserDetailsData) => void>(() => () => {});
    const [rightComponentClick, setRightComponentClick] = useState<(v: string) => void>(() => () => {});
    const [showHistoryOptions, setShowHistoryOptions] = useState(false);
    const [onCloseHistory, setOnCloseHistory] = useState<() => void>(() => () => {});
    const [histories, setHistories] = useState<HistoryTypeFormat[]>([]);
    const [historyType, setHistoryType] = useState<string | undefined>(undefined);
    const [promptSent, setPromptSent] = useState('');
    const [response, setResponse] = useState('');
    const [showingHistory, setShowingHistory] = useState(false);
    const [pageHistory, setPageHistory] = useState('');
    const { savedTheme, isMobile, isLargeTab } = useContext(ThemeContext);
    const [chatList, setChatList] = useState<Chats[]>([]);
    const [studentChatList, setStudentChatList] = useState<Chats[]>([]);
    const [flowChartList, setFlowChartList] = useState<Chats[]>([]);
    const { currentPage, fetchPage } = useDocumentContext();
    const [subPage, setSubPage] = useState('');
    const [showSidebar, setShowSidebar] = useState(true);
    const [showSidebarMobile, setShowSidebarMobile] = useState(false);
    const [cardPrompt, setCardPrompt] = useState('');
    const historyId = useRef<string | number>();
    const [pageFrom, setPageFrom] = useState<string | null>(null);
    const [isEditor, setIseEditor] = useState(false);
    const [isSmartSearch, setIsSmartSearch] = useState(false);
    const [canAutoGenerate, setCanAutoGenerate] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string>('dashboard');
    const [isFreeMode, setIsFreeMode] = useState(false);
    const [showLogin, setShowLogin] = useState(false);

    useEffect(() => {
        console.log(showSidebar);
    }, [showSidebar]);

    async function getHistory() {
        try {
            const data = await getAllHistory();

            if (data?.statusCode === 1) {
                const _data = data?.data;
                console.log('data', data);
                const filtered: HistoryTypeFormat[] = _data?.map((d: any) => {
                    return {
                        date: d.date,
                        histories: d.histories?.map((history: any) => {
                            if (history.type == historyType) {
                                return [
                                    ...history.conversation,
                                    { id: history._id },
                                    { type: history.type },
                                    { date: history.updatedAt },
                                    { title: history.title },
                                ];
                            } else
                                return [
                                    ...history.conversation,
                                    { chat_id: history?.chat_id },
                                    { id: history._id },
                                    { date: history.updatedAt },
                                    { title: history.title },
                                ];
                        }),
                    };
                });

                console.log('my_filter', filtered);

                setHistories(filtered);
            } else if (!data || data?.statusCode === 0) {
                console.log('No history');
                // alert('hdhdhd');
                setHistories([]);
                // notification.error({ message: data?.message || 'You are not connected.' });
            }
        } catch (error) {
            console.log(error);
            setHistories([]);
        }
    }

    function getQueryParams() {
        const query = new URLSearchParams(window.location.search);
        return query.get('type');
    }

    useEffect(() => {
        console.log('MyHistory', histories);
        getHistory();
    }, []);
    const getDetails = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            const details = await GetUser(token);
            console.log(details);
            if (details.statusCode == 1) {
                const { id, firstName, lastName, email, isVerified, plan, freeTrialAvailable, daysRemaining } =
                    details.data;
                const detailsFetched: UserDetailsData = details.data;

                if (!detailsFetched.profileUpdated && !(detailsFetched.isGuest ?? false)) {
                    setCompletedProfileVisible(true);
                }
                setDetails(details.data);
                if (onDetailsFetched) onDetailsFetched(detailsFetched);
                localStorage.setItem('user', JSON.stringify(detailsFetched));

                if ((!plan || !plan?.details) && !freeTrialAvailable && !(detailsFetched.isGuest ?? false)) {
                    setIsFreeMode(true);
                }

                if ((!plan || !plan?.details) && freeTrialAvailable) {
                    const lastNotified = localStorage.getItem('lastNotified');
                    if (lastNotified) {
                        // check if greater tha 3o minutes
                        if (daysRemaining <= 0) {
                            setIsFreeMode(true);
                        }
                        if (checkIfTimeIsWithinRange(30, lastNotified)) {
                            localStorage.setItem('lastNotified', Date.now().toString());
                            const goToPricing = () => navigate(chatRoutes.pricing);
                            onTrialReminder(daysRemaining, goToPricing);
                        }
                    } else {
                        localStorage.setItem('lastNotified', Date.now().toString());
                        if (daysRemaining <= 0) {
                            setIsFreeMode(true);
                            return;
                        }
                        const goToPricing = () => navigate(chatRoutes.pricing);
                        onTrialReminder(daysRemaining, goToPricing);
                        
                    }
                }

                setPackageName(plan?.details?.name);
                setUserPlan(plan);
                if (plan.expired) {
                    setIsExpiredVisible(true);
                }

                if ( details?.data?.isGuest ){
                    setShowLogin(true);
                }
            }else if (  details.statusCode == 3 && details.data ){
                toast.error("Kindly verify your email address");
                window.location.href = acquisitionRoutes.verify + '?email=' + details.data.email;
                return;
            } 
            else if (details.statusCode == 2 || details.statusCode == 401) {
                logOutUser();
                // toast.error(details.statusCode == 2 ? 'User unauthorized' : 'User already logged in on another device');
                // window.location.pathname = '/';
                setShowLogin(true);
                return;
            }

            console.log(details);
        } else {
            // eslint-disable-next-line prefer-const
            // let path = getUrlPath();
            // if (path.folder == 'dashboard') {
            //     window.location.href = '/';
            // }
            logOutUser();
            setShowLogin(true);
        }
    };

    const getTokenFromUrl = () => {
        const urlObj = new URL(window.location.href);
        const extractedToken = urlObj.searchParams.get('auth_redirect');

        if (!extractedToken) return;

        localStorage.setItem('token', extractedToken);

        urlObj.searchParams.delete('auth_redirect');

        navigate(location.pathname, { replace: true });
    };

    const setClickedHistory = useCallback(
        (id: string) => {
            const path = getUrlPath();
            if (path.pagename === chatRoutePages.smartChat || path.pagename === chatRoutePages.chat) {
                let filterHistory: any = histories
                    .flatMap((historyFormat) => historyFormat.histories)
                    .filter((history) => {
                        const chatIdObj = history.find((h: any) => h.id === id);
                        return chatIdObj !== undefined;
                    })
                    .flat();

                // historyId.current = filterHistory.find((h: any) => h.chat_id)?.chat_id;
                filterHistory = filterHistory.filter((h: any) => h?.role && (h?.content || h?.new_content));
                const modifiedConversation = filterHistory.map((item: any) => {
                    return {
                        ...item, // Copy all existing properties of the item
                        content: item.content || (item.new_content.length > 0 ? item.new_content : item.content), // Use `new_content` if `content` is empty
                        new_content: item.content ? item.new_content : [], // Clear `new_content` if it was moved to `content`
                    };
                });

                // Set the chat list and save the updated history to localStorage
                setChatList(modifiedConversation);
                console.log('Chats', chatList);
                localStorage.setItem('activeChat', JSON.stringify(filterHistory));
            }
            if (!path.pagename.includes('chat')) {
                let filterHistory: any = histories
                    .flatMap((historyFormat: any) => historyFormat.histories)
                    .filter((history: any) => {
                        const chatIdObj = history.find((h: any) => h.id === id);
                        return chatIdObj !== undefined;
                    })
                    .flat();

                // historyId.current = filterHistory.find((h: any) => h.chat_id)?.chat_id;
                filterHistory = filterHistory.filter((h: any) => h?.role && h?.content);
                const userPrompt = filterHistory.find((element: any) => element.role == 'user');
                const assistantResponse = filterHistory.find((element: any) => element.role == 'assistant');

                setPromptSent(userPrompt.content);
                setResponse(assistantResponse.content);
                setShowingHistory(true);
            }
        },
        [histories, setPromptSent, setResponse, setShowingHistory],
    );

    // get user details on each page moved to
    useEffect(() => {
        getTokenFromUrl();
        getDetails();
    }, [window.location.pathname]);

    useEffect(() => {
        fetchPage();
    }, []);

    const side = () => {
        if (isMobile) {
            setShowSidebar(false);
        } else {
            setShowSidebar(true);
        }
    };

    useEffect(() => {
        side();
    }, [isMobile]);

    useEffect(() => {
        const { pathname } = window.location;
        const split = pathname.split('/');
        const last = split[split.length - 1];

        setPathName(last);
    }, [window.location.pathname]);

    useEffect(() => {
        if (showRightComponent && !isMobile) {
            showHistory && setShowHistory(false);
        }
        if (showHistory && !isMobile) {
            showRightComponent && setShowRightComponent(false);
        }
    }, [showRightComponent, showHistory, isMobile, setShowHistory, setShowRightComponent]);

    const handleSidebarSelected = useCallback((v: string) => {
        localStorage.setItem('activeModule', v);
        setSelected(v);
    }, []);

    const valueCheck = useCallback((id: string) => {
        console.log('hdhd -----');
    }, []);

    const handleDeleteContentHistory = async (id: string) => {
        if (historyType) {
            await handleDeleteHistory(id, historyType);
        }
        await getHistory();
        toast.success('Chat deleted successfully');
    }; // Dependencies

    const clearAllContentHistory = useCallback(async () => {
        if (!historyType) return;
        await handleDeleteAllWithTypeHistory(pageHistory, historyType);
        await getHistory();
        toast.success('Chat cleared successfully');
    }, [pageHistory, historyType, getHistory]);

    console.log('I fucking need a page name ', getUrlPath().pagename);

    return (
        <div className="bg-custom-opacity h-screen">
            <div
                style={isMobile ? { flexDirection: 'column' } : {}}
                className="flex h-full overflow-hidden w-full  mx-auto bg-custom-opacity 3xl:max-w-[1800px] 3xl:mx-auto "
            >
                {getUrlPath().pagename != 'pricing' && getUrlPath().pagename != 'smart_chat' && (
                    <Helmet>
                        <script type="application/ld+json">{JSON.stringify(dashboardData)}</script>
                        <meta name="google-site-verification" content="9kRjjJnXQxcAzarlLOs0gHWJCAfJTZ01WZaUSK-tTec" />
                        <meta
                            name="description"
                            content="Create high-quality content, boost productivity and enhance work efficiency with this simple-to-use AI tool—GMind AI: the future at your fingertips."
                        />
                        <meta name="title" content="GMind AI: AI Productivity For Everyone" />
                        <title>GMind AI: AI Productivity For Everyone</title>
                        <link rel="canonical" href={`https://gmind.ai/${getUrlPath().pagename}`} />
                        <meta name="keywords" content="gmind, ai, chat, gmind, gmindai" />
                    </Helmet>
                )}
                {isMobile && (
                    <MobileNavbar
                        style={mobileNavStyle}
                        isGuest={details?.isGuest}
                        showMobileHistory={showMobileHistory}
                        showSidebar={() => {
                            setShowSidebarMobile(true);
                            setShowMobileElements((c) => ({ ...c, showSidebarMobile: !c.showSidebarMobile }));
                            if (showMobileElements.showHistory) {
                                setShowMobileElements((c) => ({ ...c, showHistory: false }));
                            }
                        }}
                        className="sticky top-0 left-0 right-0  z-[200] bg-custom-no-opacity dark:bg-[#212121]"
                        showHistory={() => {
                            setShowMobileElements((c) => ({ ...c, showHistory: !c.showHistory }));
                            if (showMobileElements.showSidebarMobile) {
                                setShowMobileElements((c) => ({ ...c, showSidebar: false }));
                            }
                        }}
                    />
                )}

                {!isMobile && (
                    <div className="hidden sm:hidden md:hidden lg:block">
                        {showSidebar && (
                            <>
                                {[
                                    'corporate-admin',
                                    'school-admin',
                                    'staff',
                                    'ticket',
                                    'teachers',
                                    'add-teacher',
                                    'add-staff',
                                    'school-profile',
                                    'corporate-profile',
                                ].includes(getUrlPath().pagename) ? (
                                    <NewSidebar
                                        setSelectedOption={setSelectedOption}
                                        selectedOption={selectedOption}
                                        adminType="school-admin"
                                    />
                                ) : (
                                    <Sidebar
                                        selected={(v) => {
                                            // setSelected(v);
                                        }}
                                        style={{
                                            width: sidebarCollapse ? 'auto' : '18vw',
                                            display: getUrlPath().pagename === chatRoutePages.pricing ? 'none' : 'flex',
                                        }}
                                        collapsed={(b: boolean) => {
                                            setSidebarCollapse(b);
                                        }}
                                        className="flex-1 h-screen"
                                        onBecomeAffiliate={() => setIsAffiliateModalVisible(true)}
                                        userIsAffiliate={details?.isAffiliate ?? false}
                                        hasPendingAffiliateRequest={details?.isAffiliateRequestPending ?? false}
                                        fetchPage={fetchPage}
                                        canShowAffiliate={details?.hasPlan ?? false}
                                        onReturnToChat={() => setShowSchoolHeader(false)}
                                        isGuest={details?.isGuest}
                                        isSmartSearch={isSmartSearch}
                                    />
                                )}
                            </>
                        )}
                    </div>
                )}
                <div className="sm:block md:block xl:hidden">
                    {showSidebarMobile && (
                        <MobileSidebar
                            onMobileClose={() => {
                                setShowSidebarMobile(false);
                                setShowMobileElements((c) => ({ ...c, showSidebarMobile: false }));
                            }}
                            style={{
                                position: 'fixed',
                                transform: showMobileElements.showSidebarMobile
                                    ? 'translateX(0)'
                                    : 'translateX(-20rem)',
                                transition: 'all 0.2s ease',
                                width: isLargeTab ? `40vw` : `65vw`,
                            }}
                            isMobile={isMobile}
                            className="top-0 bottom-0 z-[200]     left-0  h-[100vh!important]"
                            selected={(v) => {
                                // const pagePath = getUrlPath();
                                // const folder = v === chatRoutePages.chat ? 'dashboard' : pagePath.folder;
                                // navigate(`/${folder}/${v}`);
                            }}
                            onBecomeAffiliate={() => setIsAffiliateModalVisible(true)}
                            userIsAffiliate={details?.isAffiliate ?? false}
                            hasPendingAffiliateRequest={details?.isAffiliateRequestPending ?? false}
                        />
                    )}
                </div>

                {/* {isMobile && (
                <History
                    data={history ?? []}
                    onClose={() => {
                        setShowMobileElements((c) => ({ ...c, showHistory: false }));
                    }}
                    isMobile={isMobile}
                    className="transition-all dark:bg-black bg-white"
                    collapsed={() => {
                        setShowMobileElements((c) => ({ ...c, showHistory: false }));
                    }}
                    click={setClickedHistory}
                    style={{
                        zIndex: '400',
                        position: 'fixed',
                        width: showMobileElements.showHistory ? '75vw' : '0',
                        opacity: showMobileElements.showHistory ? '1' : '0',
                        userSelect: showMobileElements.showHistory ? 'all' : 'none',
                    }}
                />
            )} */}
                <section
                    style={{
                        width: showSidebar ? (sidebarCollapse ? '100%' : '100%') : '100vw',
                    }}
                    className="flex flex-col h-screen w-fit"
                >
                    <div>
                        {showTop && !isMobile && showSchoolHeader ? (
                            <SchoolHeader
                                menu={menu}
                                schoolAdminPageName={schoolAdminPageName}
                                showInput={showInput}
                                value={value}
                                setValue={setValue}
                                isAffiliate={isAffiliate}
                                refcode={details?.refcode}
                            />
                        ) : (
                            <>
                                {showTop && (
                                    <div className="hidden lg:hiddeen xl:block">
                                        {
                                            <TopNavBar
                                                isHistory={showHistoryModal}
                                                canNavigateHome={canNavigateHome}
                                                sidebarCollapse={sidebarCollapse}
                                                onShowHistoryOption={() => {
                                                    setShowHistoryModal(true);
                                                }}
                                                showHistoryOption={showHistoryOption ?? false}
                                                isMobile={isMobile}
                                                topChild={topExtraData}
                                                onClickPage={onClickPage}
                                                showPagePath={showPagePath}
                                                gmindTyping={gmindTyping ?? undefined}
                                                packageName={undefined}
                                                breadCrumbPage={currentPage}
                                                subPage={subPage}
                                                setChatList={(chats: Chats[]) => {
                                                    setChatList(chats);
                                                }}
                                                isGuest={details?.isGuest}
                                                isPricing={getUrlPath().pagename === chatRoutePages.pricing}
                                            />
                                        }
                                    </div>
                                )}
                            </>
                        )}
                        {/* Render Header only for 'corporate-admin' and 'school-admin' pages */}
                        {[
                            'corporate-admin',
                            'school-admin',
                            'staff',
                            'ticket',
                            'teachers',
                            'add-teacher',
                            'add-staff',
                        ].includes(getUrlPath().pagename) && <Header label={selectedOption} />}
                    </div>
                    <main
                        className={
                            `min-h-screen w-full flex flex-row gap-2 max-md:w-screen overflow-auto  bg-custom-opacity ${getUrlPath().pagename != 'pricing' ? 'dark:bg-[#212121]' : ''} ` +
                            middleClass
                        }
                    >
                        <div className={`${showHistoryModal && !isMobile ? 'w-[70%]' : 'w-full '}`}>
                            {getUrlPath().pagename === chatRoutePages.chat && chatList.length !== 0 && (
                                <Tooltip
                                    className="custom-tooltip flex md:hidden"
                                    title={
                                        getUrlPath().pagename === chatRoutePages.chat
                                            ? 'Create New Chat'
                                            : getUrlPath().pagename === chatRoutePages.smartChat
                                              ? 'Create New Search'
                                              : 'Create New Chat'
                                    }
                                    placement="right"
                                    overlayInnerStyle={{
                                        backgroundColor: 'white',
                                        color: 'black',
                                        border: '1px solid #ddd',
                                        borderRadius: '4px',
                                    }}
                                    // This renders the tooltip outside of the scrollable container
                                >
                                    <button
                                        onClick={() => {
                                            setChatList([]);
                                            setCardPrompt('');
                                            historyId.current = undefined;
                                            if (getUrlPath().pagename === chatRoutePages.smartChat ) {
                                                localStorage.removeItem('activeChatWithInternet');
                                                return;
                                            }
                                            localStorage.removeItem('activeChat');
                                        }}
                                        className="flex items-center  justify-center ml-6 w-6 h-6  lg:w-10 lg:h-10 rounded-ful bg-transparent border-[1.5px] border-gray-700 dark:border-white text-gray-700 dark:text-white hover:bg-gray-300 dark:hover:bg-gray-700 focus:outline-none shadow-md"
                                    >
                                        <EditOutlined />
                                    </button>
                                </Tooltip>
                            )}
                            <Outlet
                                context={{
                                    setShowMobileHistory,
                                    setShowTop,
                                    setShowSidebar,
                                    setRightComponentName,
                                    setRightComponentPlaceholder,
                                    setRightComponentData,
                                    setHistory,
                                    setMiddleClass,
                                    setMobileNavStyle,
                                    selectedHistory,
                                    setShowPagePath,
                                    setTopExtraData,
                                    setCloseIdValue,
                                    setShowHistoryOption,
                                    setGmindTyping,
                                    setCanNavigateHome,
                                    setShowExpiredModal,
                                    setShowCompleteProfileModal,
                                    setIsAffiliate,
                                    setMenu,
                                    setShowInput,
                                    setShowSchoolHeader,
                                    setShowRightComponent,
                                    setSchoolAdminPageName,
                                    setValue,
                                    setRightComponentClick,
                                    setOnDetailsFetched,
                                    setOnDeleteAllHistory,
                                    setOnDeleteHistory,
                                    setOnClickPage,
                                    setSelectedHistory,
                                    setShowHistory,
                                    setShowHistoryOptions,
                                    setHistoryVisible,
                                    setOnCloseHistory,
                                    setHistoryType,
                                    setPageHistory,
                                    showingHistory,
                                    response,
                                    promptSent,
                                    getHistory,
                                    setPromptSent,
                                    setResponse,
                                    setShowingHistory,
                                    historyType,
                                    refcode: details?.refcode,
                                    chatList,
                                    setChatList,
                                    subPage,
                                    setSubPage,
                                    showHistoryModal,
                                    showSidebar,
                                    cardPrompt,
                                    setCardPrompt,
                                    historyId,
                                    isSmartSearch,
                                    setIsSmartSearch,
                                    setCanAutoGenerate,
                                    canAutoGenerate,
                                    details,
                                    studentChatList,
                                    setStudentChatList,
                                    flowChartList,
                                    setFlowChartList,
                                }}
                            />
                        </div>
                        <HistoryModal
                            click={setClickedHistory}
                            data={histories}
                            onClose={() => {
                                isMobile
                                    ? setShowMobileElements((c) => ({ ...c, showHistory: false }))
                                    : setShowHistoryModal(false);
                            }}
                            hidden={isMobile ? showMobileElements.showHistory : showHistoryModal}
                            topChild={topExtraData}
                            closeId={closeIdValue}
                            onDeleteHistory={handleDeleteContentHistory}
                            onDeleteAllHistory={clearAllContentHistory}
                        />
                    </main>
                </section>
                {/* {showHistory && PathName === 'chats' && (
                <History
                    click={setClickedHistory}
                    data={history ?? []}
                    style={{}}
                    isHidden={(v: boolean) => setHistoryHidden(v)}
                    className=""
                    onClose={() => {
                        setHistoryCollapse(!historyCollapse);
                    }}
                />
            )} */}
                {/* {PathName !== 'chat' && ( */}

                {/* )} */}
                <ArticleModal
                    click={(id: string) => {
                        selectedHistory(id);
                    }}
                    data={rightComponentData ?? []}
                    onClose={() => {
                        onCloseHistory && onCloseHistory();
                    }}
                    hidden={historyVisible ?? false}
                    name={rightComponentName ?? 'Comments'}
                />
                {showRightComponent && (
                    <RightComponent
                        isMobile={isMobile}
                        click={rightComponentClick ?? (() => {})}
                        name={rightComponentName ?? ''}
                        placeholder={rightComponentPlaceholder ?? ''}
                        data={rightComponentData ?? []}
                        isHidden={(v: boolean) => setHistoryHidden(v)}
                        className=""
                    />
                )}
                {showExpiredModal && (
                    <ExpiredPackageModal
                        isVisible={isExpiredVisible}
                        packageName={userPlan?.details?.name ?? 'G-Basic'}
                        amountDetails={userPlan?.details?.amountDetails}
                        isOnAffiliate={getUrlPath().folder === 'affiliate' ? true : false}
                    />
                )}

                { ( isFreeMode && getUrlPath().pagename !== chatRoutePages.pricing ) && <FreeExpiredPackageModal isVisible={isFreeMode} />}

                {/* {showCompleteProfileModal && <CompleteProfile isVisible={completedProfileVisible} />} */}

                {isAffiliateModalVisible && (
                    <AffiliateConfirmationModal
                        isVisible={isAffiliateModalVisible}
                        onClose={() => setIsAffiliateModalVisible(false)}
                        showModal={() => {
                            setIsAffiliateModalVisible2(true);
                            getDetails();
                        }}
                    />
                )}

                {
                    ( showLogin && getUrlPath().pagename !== chatRoutePages.pricing ) && <LoginPromptModal 
                    visible={showLogin}
                    isNotSignedIn={details?.isGuest ? true : false}
                />}

                {isAffiliateModalVisible2 && (
                    <AffiliatePendingModal
                        isModalVisible={isAffiliateModalVisible2}
                        setIsModalVisible={setIsAffiliateModalVisible2}
                    />
                )}
                <ToastContainer />
            </div>
        </div>
    );
};

export default DashboardFrame;
