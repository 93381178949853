/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { NewContentProps, newGenerationHomeDataProps } from '../interface';
import BackButton from '@src/components/BackButton';
import { newGenerationHomeData } from '../constants';
import chevronRight from '@icons/chevron-right.svg';
import LessonMaterials from '../sub_pages/lesson_materials';
import ContentWriter from '../sub_pages/content_writer';
import LetterWriter from '../sub_pages/letter_writer';
import SocialMediaContent from '../sub_pages/social_media';
import { useNavigate, useOutletContext } from 'react-router';
import { ThemeContext } from '@src/theme_provider/theme';
import { RightOutlined } from '@ant-design/icons';
import './global.scss';
import NewLetterWriter from '../sub_pages/letter_writter/lesson';

const NewGenerateContent = ({
    onGenerate,
    setPageName,
    currentIndex = 0,
    setTopic,
    setPageNumber,
}: NewContentProps) => {
    const [currentPage, setCurrentPage] = useState<number>(currentIndex);
    const [contentOptions, setContentOptions] = useState<newGenerationHomeDataProps[]>(newGenerationHomeData);
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user') ?? '{}');
    const { savedTheme, toggleTheme } = useContext(ThemeContext);
    const { subPage, setSubPage, showHistoryModal, setShowTop, setShowSidebar, response, setResponse } =
        useOutletContext<any>();

    useEffect(() => {
        if (currentPage === 0) {
            setSubPage('');
        }
    }, [subPage]);

    const setSubPageName = (index: number) => {
        switch (index) {
            case 0:
                setSubPage('');
                break;
            case 1:
                setSubPage('Short General Content Creator');
                break;
            case 2:
                setSubPage('Learning Materials Generator');
                break;
            case 3:
                setSubPage('Comprehensive Content Creator');
                break;
            case 6:
                setSubPage('Make Research');
                break;
            default:
                console.log('Invalid page');
                setSubPage('');
        }
        console.log('SubPage', subPage);
    };

    // useEffect(() => {
    //     if (user.plan && user.plan?.restrictions) {
    //         const canUseLetter = user.plan.restrictions.letterWriter;
    //         const canUseArticleWritter = user.plan.restrictions.articleWriter;

    //         const contentOptions = newGenerationHomeData.filter((e) => {
    //             if (e.label === 'letter_writer' && !canUseLetter) return false;
    //             if (e.label === 'article' && !canUseArticleWritter) return false;

    //             return true;
    //         });

    //         setContentOptions(contentOptions);
    //     } else if (user.freeTrialAvailable) {
    //         const canUseLetter = true;
    //         const canUseArticleWritter = true;

    //         const contentOptions = newGenerationHomeData.filter((e) => {
    //             if (e.label === 'letter_writer' && !canUseLetter) return false;
    //             if (e.label === 'article' && !canUseArticleWritter) return false;

    //             return true;
    //         });

    //         setContentOptions(contentOptions);
    //     } else {
    //         const contentOptions = newGenerationHomeData.filter((e) => {
    //             if (e.label === 'letter_writer') return false;
    //             if (e.label === 'article') return false;

    //             return true;
    //         });

    //         setContentOptions(contentOptions);
    //     }
    // }, [window.location.pathname]);

    return (
        <div
            className={`w-full flex   ${currentPage === 1 || currentPage === 3 ? 'md:h-screen' : 'md:h-full'} flex-col ${currentPage > 0 ? (currentPage !== 1 && currentPage !== 3 && currentPage !== 4 ? 'pt-5 pb-20' : 'pt-0 pb-0') : 'pt-7 pb-2'} px-[2rem] gap-y-2`}
        >
            {currentPage !== 1 && currentPage !== 3 && currentPage !== 4 && currentPage !== 2 && currentPage > 0 && (
                <BackButton
                    onclick={() => {
                        setCurrentPage(0);
                        setPageNumber(0);
                        setShowSidebar(true);
                        setShowTop(true);
                        setResponse('');

                        setPageName('');
                        setSubPage('');
                    }}
                />
            )}
            {(currentPage === 1 || currentPage === 3) && (
                <ContentWriter
                    response={response}
                    setResponse={setResponse}
                    isComprehensive={currentPage === 3}
                    setCurrentPage={setCurrentPage}
                    setPageName={setPageName}
                    setPageNumber={setPageNumber}
                    setShowSidebar={setShowSidebar}
                    setShowTop={setShowTop}
                    setSubPage={setSubPage}
                />
            )}
           
            {currentPage === 4 && (
                <NewLetterWriter
                    response={response}
                    setResponse={setResponse}
                    setCurrentPage={setCurrentPage}
                    setPageName={setPageName}
                    setPageNumber={setPageNumber}
                    setShowSidebar={setShowSidebar}
                    setShowTop={setShowTop}
                    setSubPage={setSubPage}
                    pageName={'Letter Writer'}
                />
            )}

            {currentPage === 0 && (
                <div className="w-full flex flex-col items-center justify-center">
                    <h2 className="self-start md:self-center dark:text-white font-medium text-[2rem] md:text-[2.3rem] text-[var(--gmind-black)] text-center">
                        Generate Different Content Types
                    </h2>
                    <p className="mx-0 md:mx-20 self-start dark:text-gray-300 md:self-center text-[1.125rem] md:text-[0.8rem] text-gray-700 text-center mb-2">
                        {' '}
                        Explore standardized content tailored to your needs! Click to unlock resources,
                        carefully curated to align with academic standards, tailored to meet your unique needs and goals
                    </p>

                    <div className="flex flex-wrap justify-center items-center mb-20  gap-x-2 my-3  w-full">
                        {contentOptions.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => {
                                    setPageName(item.pageName);

                                    setSubPage(item.pageName);

                                    if (
                                        item.pageIndex === 3 ||
                                        item.pageIndex === 1 ||
                                        item.pageIndex === 4 ||
                                        item.pageIndex === 2
                                    ) {
                                        setShowSidebar(false);
                                        setShowTop(false);
                                    } else {
                                        setShowSidebar(true);
                                        setShowTop(true);
                                    }
                                    if (item.naviagatePage) {
                                        navigate(`/${item.label}`);
                                        return;
                                    }
                                    setResponse('');
                                    setCurrentPage(item.pageIndex);
                                    setPageNumber(item.pageIndex);
                                }}
                                className={`" ${showHistoryModal ? 'xl:w-[calc(43.33%-8px)]' : 'xl:w-[calc(33.33%-8px)] md:w-[65%] lg:w-[55%] w-full'}  flex flex-row dark:bg-transparent  dark:shadow-lg dark:hover:shadow-gray-300 items-center h-[6.5rem] px-2.5 py-2.5 mb-3 bg-white shadow shadow-md rounded-lg 
                 cursor-pointer hover:shadow-2xl"`}
                                style={{
                                    border:
                                        savedTheme === 'dark'
                                            ? '2px solid white'
                                            : '1px solid var(--gmind-border-black8)',
                                }}
                            >
                                <div className="w-full flex  flex-row items-center justify-between gap-x-2">
                                    <div className="flex items-center gap-x-2">
                                        {item.pageName.toLowerCase() === 'assessment' ? (
                                            <div className="flex flex-col items-center justify-center w-20 h-10 bg-black rounded-md">
                                                {item.imgElement ?? (
                                                    <img
                                                        className="w-12 md:w-auto h-12 md:h-auto"
                                                        src={item.img}
                                                        alt={item.head}
                                                    />
                                                )}
                                            </div>
                                        ) : (
                                            <img
                                                className="w-12 md:w-auto h-12 md:h-auto"
                                                src={item.img}
                                                alt={item.head}
                                            />
                                        )}
                                        <div className="flex flex-col gap-y-1">
                                            <h5
                                                className={`${showHistoryModal ? 'text-[0.6rem]' : 'text-xs'}"font-medium  dark:text-white md:text-sm text-gray-800"`}
                                            >
                                                {item.head}
                                            </h5>
                                            <p
                                                className={` ${showHistoryModal ? 'text-[0.4rem]' : 'text-[0.6rem]'}"font-normal dark:text-gray-300 text-[0.6rem] md:text-[0.6rem] text-[var(--gmind-white60)]"`}
                                            >
                                                {item.text}
                                            </p>
                                        </div>
                                    </div>

                                    <RightOutlined className="text-[var(--gmind-black)] dark:text-white text-[0.7rem]" />
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* <div className="slf-start md:self-center my-3 items-center justify-center w-full grid-cols-1  grid lg:grid-cols-3 gap-x-2 ">
                        {contentOptions.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => {
                                    if (item.naviagatePage) {
                                        navigate(`/dashboard/${item.pageName}`);
                                        return;
                                    }
                                    setCurrentPage(item.pageIndex);
                                    setPageNumber(item.pageIndex);
                                    setPageName(item.pageName);
                                    setShowSidebar(item.pageIndex == 2 || item.pageIndex == 4 || item.pageIndex == 5);
                                }}
                                className={`w-full rounded-[0.75rem] bg-white px-[0.65rem]  mb-3 md:mb-3  py-[0.65rem] md:h-25 md:min-h-[7rem] md:max-h-40 flex items-center cursor-pointer shadow-gray-500  shadow-md hover:shadow-2xl`}
                                style={{
                                    border: '1px solid var(--gmind-border-black8)',
                                }}
                            >
                                <div className="w-full flex items-center justify-between gap-x-2">
                                    <div className="flex gap-x-2 items-center">
                                        {item.pageName.toLowerCase() === 'assessment' ? (
                                            <div className="flex flex-col items-center justify-center w-[5rem] h-[2.5rem] bg-[var(--gmind-black)] rounded-[0.5rem]">
                                                {item.imgElement ?? (
                                                    <img
                                                        className="w-[3rem] md:w-auto h-[3rem] md:h-auto"
                                                        src={item.img}
                                                        alt={item.head}
                                                    />
                                                )}
                                            </div>
                                        ) : (
                                            <img
                                                className="w-[3rem] md:w-auto h-[3rem] md:h-auto"
                                                src={item.img}
                                                alt={item.head}
                                            />
                                        )}
                                        <div className="flex flex-col gap-y-1">
                                            <h5 className="font-bold text-[0.7rem]  md:text-[0.9rem] text-[var(--gmind-light-black)]">
                                                {item.head}
                                            </h5>
                                            <p className="font-normal text-[0.6rem] md:text-[0.6rem] text-[var(--gmind-white60)]">
                                                {item.text}
                                            </p>
                                        </div>
                                    </div>

                                    <img className="self-center" src={chevronRight} alt="syllaus" />
                                </div>
                            </div>
                        ))}
                    </div> */}
                </div>
            )}
        </div>
    );
};
export default NewGenerateContent;
