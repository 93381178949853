/* eslint-disable prefer-const */
/* eslint-disable no-case-declarations */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import './style.css';
import toPlaintext from 'quill-delta-to-plaintext';

import { useContext, useEffect, useRef, useState } from 'react';
import {
    GetAllComments,
    GetDocument,
    GetVersionHistories,
} from '@src/core/endpoints/essay';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { notification } from 'antd';
import moment from 'moment';
import TopNavMobile from '@src/components/Top/mobile';
import share from '@assets/chat/share.svg';
import download from '@assets/chat/download.svg';
import { toast } from 'react-toastify';
import { Socket, io } from 'socket.io-client';
import { BASE_URL } from '@src/utils/constants';
import { SubNavBar } from './SubNav';
import axios from 'axios';
import TopNavBar from '@src/components/Top';
import DOMPurify from 'dompurify';

import { Share } from '@src/pages/dashboard/components/Share';
import { Download } from '@src/pages/dashboard/components/Share/Download';
import { GiShipWheel } from 'react-icons/gi';
import Like from '@public/like.svg';
import DisLike from '@public/dislike.svg';
import Regenerate from '@assets/repeat.svg';
import { marked } from 'marked';
import { ChatType } from '@src/core/interfaces/chat';
import { DisLikeImage, LikedImage } from './svgs';
import html2md from 'html-to-md';
import { handleTextReplace, isHtmlString, replaceImageElement } from '@src/utils/app_functions';
import { convertHTMLToPDF, convertHTMLToWord, onRegenerateConfirm } from '../functions';
import { UserAddOutlined } from '@ant-design/icons';
import InviteModal from '@src/pages/dashboard/components/InviteModal';
import { useDocumentContext } from '@src/context/DocumentContext';
import { useUserContext } from '@src/context/UseProvider';
import LoginPromptModal from './components/modal';
import { uploadToCloudinary } from '../../slide/upload';
import { ThemeContext } from '@src/theme_provider/theme';

type DataType<T> = {
    title: T;
    content: T | any;
    id: T;
};

interface DocChanges {
    data: string;
    documentId: string;
}

const NewEditorPage = ({
    content,
    isTyping,
    showingHistory,
    setGenerate,
    generate,
    setShowingHistory,
    editorContent,
    setEditorContent,
    setTranslatedContent,
    setHumanizedContent,
    setUserOnDoc,
    showInvite,
    showTopNavBar,
    regenerate,
    editorInstanceRef,
    isBack,
    highlightedTexts,
    setHighlightedTexts,
    setLatestRespone,
    latestRespone,
    isContent,
    setIsMobileStep,
    docId,
    setDocId,
}: {
    content: React.ReactNode;
    showingHistory?: any;
    Profile?: any;
    promptSent?: any;
    isTyping: any;
    setShowingHistory?: any;
    generate: any;
    setGenerate: any;
    editorContent: any;
    setEditorContent: any;
    setTranslatedContent: any;
    translatedContent: any;
    humanizedContent: any;
    setHumanizedContent: any;
    setUserOnDoc: any;
    userOnDoc: any;
    showInvite: any;
    showTopNavBar: boolean;
    regenerate: any;
    editorInstanceRef: React.MutableRefObject<any>;
    isBack?: boolean;
    highlightedTexts: string;
    setHighlightedTexts: React.Dispatch<React.SetStateAction<string>>;
    latestRespone: string;
    setLatestRespone: React.Dispatch<React.SetStateAction<string>>;
    isContent?: boolean;
    setIsMobileStep?:React.Dispatch<React.SetStateAction<number>>;
    docId:string | undefined;
    setDocId: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
    const [searchParams] = useSearchParams();
    const [socket, setSocket] = useState<Socket | null>(null);
    const [edit, setEdit] = useState<boolean>(true);
    const [comments, setComments] = useState<DataType<string>[]>([]);
    const [hasAccess, setHasAccess] = useState(false);
    const [middleOption, setMiddleOption] = useState('Comments');
    const [currentData, setCurrentData] = useState<DataType<string>[]>([]);
    const [right, setRight] = useState<{ name: string; placeholder: string; date?: number }>({
        name: '',
        placeholder: '',
        date: Date.now(),
    });
    const token = localStorage.getItem('token');
    const UrlRef = useRef<string>('');
    const [streamEnded, setStreamEnded] = useState(false);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [versionHistories, setVersionHistories] = useState<DataType<string>[]>([]);
    const userStorage = localStorage.getItem('user');
    const user = userStorage ? JSON.parse(userStorage) : { id: '' };
    const documentId = searchParams.get('document');
    const [translated, setTranslated] = useState<boolean>(true);
    const Document = useRef<any>();
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const {isMobile} = useContext(ThemeContext);
    const [mount, setMount] = useState(false);
    const navigate = useNavigate();
    // Humanize

    const [humanizing, setHumanizing] = useState(false);
    const [editorData, setEditorData] = useState('');

    const [pageId, setPageId] = useState<string>('');
    const [showContent, setShowContent] = useState(false);
    const [isRegenerating, setIsRegenerating] = useState(false);
    // const [response, setResponse] = useState<string>('');
    const StreamId = useRef<any>();
    const DocumentId = useRef('');
    const [isOwner, setIsOwner] = useState(false);
    const [isLiked, setIsLike] = useState(false);
    const [disLike, setDisLike] = useState(false);
    const [replacedText, setReplacedText] = useState<string[]>([]);
    const [canTranslate, setCanTranslate] = useState(false);
    const [canHumanize, setCanHumanize] = useState(false);
    const [pdfDownloading, setPdfDownloading] = useState(false);
    const [wordDownloading, setWordDownloading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [docChanges, setDocChanges] = useState<DocChanges[]>([]);

    const { userDetails } = useUserContext();
    const [isNotLoggedIn, setIsNotLoggedIn] = useState(false);
    const [pageFrom, setPageFrom] = useState<string | null>(null);
    const { error, document, documentResponse } = useDocumentContext();
    const [sockets, setSockets] = useState<{ [key: string]: Socket | null }>({});
    const [isTweaking, setIsTweaking] = useState<{ [key: string]: boolean }>({});
    const [fieldTexts, setFieldTexts] = useState<string>('');
    const {
        setShowMobileHistory,
        setShowTop,
        setShowSidebar,
        setRightComponentName,
        setRightComponentPlaceholder,
        setRightComponentData,
        response,
        setResponse,
        setCanNavigateHome,
        setShowRightComponent,
        setShowHistory,
        setHistoryVisible,
        showSidebar,
        details,
    } = useOutletContext<any>();


    // const scrollToBottom = () => {
    //   if (editorContainerRef.current) {
    //     const container = editorContainerRef.current as HTMLElement;
    //     const { scrollHeight, clientHeight } = container;
    //     if (scrollHeight > clientHeight) {
    //       container.scrollTop = scrollHeight;
    //     }
    //   }
    // };

    // // Scroll when content changes
    // useEffect(() => {
    //     console.log('Scroll to bottom');
    //   scrollToBottom();
    // }, [content]);
    useEffect(() => {
        const newSocket = io(BASE_URL.slice(0, -2));
        setSockets((prevSockets) => ({
            ...prevSockets,
            edit: newSocket,
        }));
        return () => {
            newSocket.close();
        };
    }, []);



    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            setIsNotLoggedIn(true);
        }
    }, [error]);

    const regenrate = async () => {
        onRegenerateConfirm({
            onConfirm: regenerate,
        });
    };

  

    useEffect(() => {
        let canUseTranslator =
            user.plan && user.plan?.restrictions ? user.plan.restrictions.contentTranslator : user.freeTrialAvailable;
        let canUseHumanizer =
            user.plan && user.plan?.restrictions ? user.plan.restrictions.canHumanize : user.freeTrialAvailable;

        console.log(canUseTranslator, canUseHumanizer, 'can use translator and humanizer');

        setCanTranslate(true);
        setCanHumanize(true);
    }, []);

    useEffect(() => {
        const Socket = io(BASE_URL.slice(0, -2));
        setSocket(Socket);

        // const essayDocument = localStorage.getItem('essay_data');

        // if (!essayDocument) {
        //     getDocument()
        //         .then((d) => {
        //             console.log(d, 'is d');
        //             setEditorData(d?.data ?? '');
        //         })
        //         .catch((e) => {
        //             console.log('error is', e);
        //         });
        // }
        setEditorData(document?.data ?? '');
        setEditorContent(document?.data ?? '');

        // !essayDocument && getDocument();
        // !essayDocument && getVersionHistories();
        setMount(true);
    }, [document]);

    async function getDocument() {
        try {
            const response = await GetDocument(token, documentId);
            if (response.statusCode !== 1) {
                setHasAccess(false);
                console.error('Something went wrong while getting documents');
                return;
            }
            if (response?.owner) {
                setIsOwner(true);
                setHasAccess(true);
            } else if (response?.hasAccess) {
                setHasAccess(true);
            }
            Document.current = response.data;
            return response.data;
        } catch (error) {
            console.log(error, 'is an getdoc error');

            return { error };
        }
    }

    const handledLike = () => {
        setIsLike(!isLiked);
    };

    const handledDisLike = () => {
        setDisLike(!disLike);
    };

    const handleRefetchUnevenInitilDocumentFetch = async () => {
        if (!Document?.current) {
            await getDocument();
        }
    };

    async function getVersionHistories() {
        const response = await GetVersionHistories(token, documentId);

        if (response.statusCode !== 1) {
            console.log("Can't get version histories due to a problem");
            return;
        }

        setVersionHistories(response.data);
        return response.data;
    }


    async function handleInvite() {
        const isOwner = documentResponse?.owner;
        if (isOwner) {
            setIsModalVisible(true);
        } else {
            toast.error('You are not the owner of this document');
        }
    }

    async function handleShare() {
        // await handleRefetchUnevenInitilDocumentFetch();
        let data = '';

        if (isHtmlString(editorContent)) {
            data = html2md(editorContent);
        } else {
            data = editorContent;
        }
        // const data = Document.current?.data;

        if (UrlRef.current) {
            setShowShareModal(true);
            return;
        }
        const response = await axios.post(`${BASE_URL.replace('/v1', '')}/markdown-to-doc`, { content: data }, {});

        const { data: d } = response;
        if (d?.statusCode === 1) {
            const url = `${BASE_URL.replace('/v', '')}/${d?.url}`;
            UrlRef.current = url;
            setShowShareModal(true);
        }
    }


    const handleBase64ImageUpload = async (htmlcontent: string) => {
        if (!editorInstanceRef?.current) {
            console.error('Editor instance is not initialized.');
            return;
        }

        const content = htmlcontent;
        const base64ImageRegex = /<img[^>]+src="data:image\/(png|jpeg|jpg|gif);base64,([^"]+)"/g;

        let match;
        let updatedContent = content;

        while ((match = base64ImageRegex.exec(content)) !== null) {
            const base64Image = match[0];
            const base64Data = match[2];

            try {
                const url = await uploadToCloudinary(base64Data);

                updatedContent = updatedContent.replace(base64Image, `<img src="${url}" />`);
                setEditorContent?.(updatedContent);
            } catch (error) {
                console.error('Error uploading image:', error);
            }
            return updatedContent;
        }

        editorInstanceRef.current.setContents(updatedContent);
    };

    function cleanHTMLContent(htmlContent: string): string {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');

        const imgTags = doc.querySelectorAll('img');
        imgTags.forEach((img) => {
            img.removeAttribute('alt');
            img.removeAttribute('data-rotate');
            img.removeAttribute('data-proportion');
            img.removeAttribute('data-size');
            img.removeAttribute('data-align');
            img.removeAttribute('data-percentage');
            img.removeAttribute('data-le-name');
            img.removeAttribute('data-le-size');
            img.removeAttribute('data-origin');
            img.removeAttribute('data-file-size');
            img.removeAttribute('data-origin');
            img.removeAttribute('style');
            img.removeAttribute('data-file-name');
            img.removeAttribute('origin-size');
            img.removeAttribute('>');
        });

        return doc.body.innerHTML;
    }

    async function handlePDF() {
        setPdfDownloading(true);

        const content = editorInstanceRef.current.getContents(); // Get editor content

        let updatedContent = content;
        let data = '';

        if (isHtmlString(updatedContent)) {
            data = cleanHTMLContent(updatedContent);
        } else {
            data = await marked(updatedContent);
        }

        try {
            const updatedData = await handleBase64ImageUpload(data);
            if (updatedData) {
                await convertHTMLToPDF(updatedData);
            } else {
                await convertHTMLToPDF(data);
            }
        } catch (error) {
            toast.error('Error downloading documents');
        } finally {
            setPdfDownloading(false);
        }
    }

    // async function handleWord() {
    //     let to_html = '';

    //     if (isHtmlString(editorContent)) {
    //         to_html = editorContent;
    //     } else {
    //         to_html = await marked(editorContent);
    //     }

    //     try {
    //         setWordDownloading(true);
    //         await convertHTMLToWord(to_html);
    //     } catch (error) {
    //         toast.error('Error downloading documents');
    //     } finally {
    //         setWordDownloading(false);
    //     }

    // const response = await axios.post(`${BASE_URL.slice(0, -3)}/html-to-doc`, { content: to_html }, {});
    // const { data: d } = response;
    // if (d?.statusCode === 1) {
    //     const link = document.createElement('a');
    //     link.href = `${BASE_URL.slice(0, -3)}/${d?.url}`;
    //     link.download = 'document.docx';
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //     toast.success('Download successful');
    // }
    // }

    async function handleWord() {
        setWordDownloading(true);

        const content = editorInstanceRef.current.getContents(); // Get editor content

        let updatedContent = content;
        let data = '';

        if (isHtmlString(updatedContent)) {
            data = cleanHTMLContent(updatedContent);
        } else {
            data = await marked(updatedContent);
        }

        try {
            const updatedData = await handleBase64ImageUpload(data);
            if (updatedData) {
                await convertHTMLToWord(updatedData);
            } else {
                await convertHTMLToWord(data);
            }
        } catch (error) {
            toast.error('Error downloading documents');
        } finally {
            setWordDownloading(false);
        }
    }

    useEffect(() => {
        if (!socket) return;

        socket.on('data', (data: string) => {
            setResponse((response: any) => response + data);
        });

        socket.on('stream_end', async (data: { streamId: string; messages: ChatType[] }) => {
            const { streamId } = data;
            if (streamId === StreamId.current) {
                StreamId.current = '';
                DocumentId.current = pageId;
                const user = JSON.parse(localStorage.getItem('user') || '');

                socket.emit('get-documents', user?.id);
            }

            setIsRegenerating(false);
        });

        return () => {
            socket.off('message');
        };
    }, [socket]);

    useEffect(() => {
        if (document && docId) {
            // Register the user to the document space
            socket?.emit('register_document_space', {
                documentId: docId,
                userId: userDetails?.userId,
            });

            // Listen for document changes
            socket?.on('receive-changes', (data: { data: string; documentId: string; userId: string }) => {
                console.log(data.userId);
                console.log('userDetails?.userId');
                if (data.userId === userDetails?.userId) return;
                setEditorData(data.data);

                setEditorContent(data.data);
            });

            // Listen for users on document
            socket?.on('user_on_document', (data: any) => {
                setUserOnDoc(data);
            });

            // Cleanup listeners on component unmount or dependencies change
            return () => {
                socket?.off('receive-changes'); // Fix typo here
                socket?.off('user_on_document');
            };
        }

        // Cleanup in case no document exists
        return () => {
            socket?.off('receive-changes');
            socket?.off('user_on_document');
        };
    }, [socket, document, docId]);

    useEffect(() => {
        if (response != '') {
            setEditorData(response);
        }
    }, [response]);

 

    async function TranslateContent(content: string, target: string, textsToReplace: string[]) {
        try {
            const response = await axios.post(`${BASE_URL}/translator`, { content, target }, {});
            console.log(response.data);

            const { data } = response;
            setHumanizedContent('');
            let translatedContent = handleTextReplace(data?.data, textsToReplace);
            // console.log(convertToMarkdownFormat(translatedContent))
            setResponse(translatedContent);
            setTranslatedContent(translatedContent);
            setEditorContent(translatedContent);
            return data;
        } catch (error: any) {
            toast.error(error);
            return 0;
        }
    }

    async function handleTranslate(language: string) {
        setTranslated(false);
        let contentToTranslate = '';
        let textsToReplace: string[] = [];

        if (isHtmlString(editorContent)) {
            const replaceData = replaceImageElement(editorContent);
            contentToTranslate = html2md(replaceData.replacedText);
            textsToReplace = replaceData.extractedDivs;
        } else {
            contentToTranslate = editorContent;
        }

        const translated_response = await TranslateContent(contentToTranslate, language, textsToReplace);
        if (translated_response?.statusCode !== 1) {
            toast.error('Failed to translate: ' + translated_response?.message);
            setTranslated(true);
            return;
        }
        setTranslated(true);
        toast.success('Content Translated');
    }

    async function humanizeContent(content: string, textsToReplace: string[]) {
        try {
            const response = await axios.post(`${BASE_URL}/gmind/humanize`, { content }, {});
            const { data } = response;
            let humanizedContent = handleTextReplace(data?.data, textsToReplace);
            setHumanizedContent(humanizedContent);
            setResponse(humanizedContent);
            // set the editor content after humanizing
            setEditorContent(humanizedContent);
            return data;
        } catch (error: any) {
            toast.error(error);
            return 0;
        }
    }

    async function handleHumanize() {
        setHumanizing(true);
        let contentToHumanize = '';
        let textsToReplace: string[] = [];

        if (isHtmlString(editorContent)) {
            let replaceData = replaceImageElement(editorContent);
            contentToHumanize = html2md(replaceData.replacedText);
            textsToReplace = replaceData.extractedDivs;
        } else {
            contentToHumanize = editorContent;
        }

        const response = await humanizeContent(contentToHumanize, textsToReplace);
        if (response?.statusCode !== 1) {
            toast.error('Failed to humanize: ' + response?.message);
            setHumanizing(false);
            return;
        }
        setHumanizing(false);
        toast.success('Content Humanized');
    }

    function getComments() {
        GetAllComments(token, documentId)
            .then((d) => {
                if (d?.statusCode === 1) {
                    const Comments = d?.data?.comments as any[];
                    const formatted = Comments.map((c) => {
                        return {
                            title: c.highlightedText,
                            content: c.comment,
                            id: c._id,
                            date: moment(c.updatedAt).format('HH:mm DD-MM-YYYY'),
                        };
                    });
                    setComments(formatted as { title: string; content: string; id: string }[]);
                    setCurrentData(formatted);
                }
            })
            .catch((e) => {
                notification.error({ message: 'Something went wrong: ' + e?.message });
            });
    }

    function handleMiddleOption(v: string, setContent = true) {
        setMiddleOption(v);
        if (setContent) {
            setShowContent(true);
        }

        switch (v) {
            case 'Comments':
                if (v === right.name) {
                    setRight({ name: '', placeholder: '', date: Date.now() });
                }
                setTimeout(() => {
                    setRight((curr) => {
                        return { ...curr, name: 'Comments', placeholder: 'Be the first to add a comment!' };
                    });
                    setCurrentData(comments);
                }, 10);
                break;
            case 'Resources':
                setRight((curr) => {
                    return {
                        ...curr,
                        name: 'Resources',
                        placeholder: 'No resources yet. Add a resource.',
                        date: Date.now(),
                    };
                });
                setCurrentData([]);
                break;

            case 'Version History':
                getVersionHistories().then((d) => {
                    if (d) {
                        const formatted = d.map(({ title, content, id }: any) => {
                            const plainText = toPlaintext(content.ops);
                            return {
                                title,
                                id,
                                content: plainText,
                            };
                        });
                        setCurrentData(formatted);
                    }
                });
                setRight((curr) => {
                    return { ...curr, name: 'Version History', placeholder: 'Start by adding a version history' };
                });
                break;

            case 'History':
                getVersionHistories().then((d) => {
                    if (d) {
                        const formatted = d.map(({ title, content, id }: any) => {
                            const plainText = toPlaintext(content.ops);
                            return {
                                title,
                                id,
                                content: plainText,
                            };
                        });
                        setCurrentData(formatted);
                    }
                });
                setRight((curr) => {
                    return { ...curr, name: 'History', placeholder: 'Start by adding a version history' };
                });
                break;

            default:
                !isMobile &&
                    setRight((curr) => {
                        return { ...curr, name: 'Comments', placeholder: 'Be the first to add a comment!' };
                    });
                setCurrentData(comments);
                break;
        }
    }


    const onGenerateMessages = async (message: string, field: string) => {
        if (!message) {
            toast.error("Input can't be empty");
            return;
        }

        toast.info('Please sit tight, your beautiful content is on its way.');
        setIsTweaking((prevState) => ({ ...prevState, [field]: true }));
        setFieldTexts(field);

        const msgs = { role: 'user', content: message };
        // console.log(msgs);

        try {
            sockets['edit']?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',

                            content: message,
                            type: 'gpt-4',
                        },
                        msgs,
                    ],
                    userId: details?.userId ?? '',
                },
            });
        } catch (error) {
            // setIsTyping(false);
        } finally {
            // setIsTyping(false);
        }
    };

    useEffect(() => {
        if (!sockets['edit']) return;
        let tempBuffer = '';

        sockets['edit']?.on('data', (data: string) => {
            tempBuffer += data;
        });

        sockets['edit']?.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            const { assistant } = data;

            console.log(assistant);
            const assistantMessage = assistant[assistant.length - 1].content;
            setLatestRespone(assistantMessage);
        });

        return () => {
            sockets['edit']?.off('message');
        };
    }, [sockets['edit']]);

    const [isSet, setIsSet] = useState(false);
    useEffect(() => {
    
        const updateContent = async () => {
            const content = editorInstanceRef.current.getContents();

            console.log('highlightedTexts: ', highlightedTexts);
            console.log('content: ', content);
            console.log('latestResponse: ', latestRespone);

            if (latestRespone && content && highlightedTexts) {
                try {
                    // Escape special characters in the highlighted texts
                    const escapeRegex = (str) => str.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
                    const safeHighlightedTexts = escapeRegex(highlightedTexts);

                    // Create a case-insensitive regular expression
                    const regex = new RegExp(safeHighlightedTexts, 'i');
                    console.log('Regex:', regex);

                    // Check if the regex matches the content
                    // Replace the highlighted text with the latest response
                    const updatedResponse = content.replace(regex, latestRespone);

                    const cleanHTML = DOMPurify.sanitize(updatedResponse);
                    console.log('cleanHTML');
                    console.log('Updated Response:', updatedResponse);

                    // Update editor content
                    await editorInstanceRef.current.setContents(updatedResponse);

                    if (Response !== updatedResponse) {
                        toast.success('Content updated successfully');
                        // Use return instead of break
                    } else {
                        console.log('Content is the same. No update needed.');

                        if (setResponse) setResponse(cleanHTML); // Set the Response if successful
                        // toast.success('Content updated successfully');
                    }

                    // Wait for content update before clearing states
                    setHighlightedTexts('');
                    setLatestRespone('');
                    setIsTweaking((prevState) => ({ ...prevState, [fieldTexts]: false }));
                } catch (error) {
                    console.error('Error updating editor content:', error);
                }
            }
        };

        updateContent(); // Call the async function
    }, [latestRespone, fieldTexts]);

    useEffect(() => {
        if (!sockets) return;
        const interval = setInterval(() => {
            // hasAccess &&
            //     UpdateDocument(token, { documentId, data: { title: '', data: editor_content.current } })
            //         .then(({ status }) => {
            //             const data_exist = localStorage.getItem('essay_data');
            //             if (data_exist) {
            //                 localStorage.removeItem('essay_data');
            //             }
            //             status !== 200 && console.log('Update document failed');
            //         })
            //         .catch((e: any) => {
            //             if (e.response) {
            //                 console.error(e.response?.data?.message);
            //             } else {
            //                 console.log(e);
            //             }
            //         });
        }, 2000);

        return () => {
            clearInterval(interval);
        };
    }, [sockets]);

    const [highlightedRange, setHighlightedRange] = useState<Range | null>(null);

    const handleRewrite = async () => {
        if (!highlightedTexts) {
            toast.error('Highlight text in the editor to rewrite');
            return;
        }

        // if (setIsEditContent) setIsEditContent(true);

        const prompt = `
        Rewrite the following text. Return only the rewritten text. Do not include the original text or any additional comments:\n\n${highlightedTexts}
        `;

        await onGenerateMessages(prompt.trim(), 'rewrite');
    };
    const handleLength = async (condition: string) => {
        if (!highlightedTexts) {
            toast.error('Highlight text in the editor to rewrite');
            return;
        }

        const prompt = `
            ${condition === 'increase' ? 'Increase' : 'Decrease'} the length of the following text. Return only the updated text without any extra comments:\n\n${highlightedTexts}
        `;

        await onGenerateMessages(prompt.trim(), 'length');
    };

    const handleChangeTone = async (condition: string) => {
        if (!highlightedTexts) {
            toast.error('Highlight text in the editor to change the tone');
            return;
        }

        // if (setIsEditContent) setIsEditContent(true);

        const prompt = `
            Change the tone of the following text to be more ${condition}. Return only the updated text without any extra comments:\n\n${highlightedTexts}
        `;

        await onGenerateMessages(prompt.trim(), 'tone');
    };

    const handleImproveQuality = async (condition: string) => {
        if (!highlightedTexts) {
            toast.error('Highlight text in the editor to improve the quality');
            return;
        }

        // if (setIsEditContent) setIsEditContent(true);

        const prompt = `
            Improve the quality of the following text to make it more ${condition}. Return only the improved text without any additional comments:\n\n${highlightedTexts}
        `;

        await onGenerateMessages(prompt.trim(), 'quality');
    };

    useEffect(() => {
        setCanNavigateHome(true);
        setShowMobileHistory(false);
        setShowRightComponent(false);
        setRightComponentName(right.name);
        setRightComponentPlaceholder(right.placeholder);
        setRightComponentData(currentData);
        setShowHistory(false);
        setShowTop(false);
        setHistoryVisible(showContent);
    }, [
        setCanNavigateHome,
        setShowMobileHistory,
        setShowRightComponent,
        setRightComponentName,
        setRightComponentPlaceholder,
        setRightComponentData,
        setShowHistory,
        setShowSidebar,
        setShowTop,
        setHistoryVisible,
    ]);

    useEffect(() => {
        setShowSidebar(false);
    }, [showSidebar]);


    return (
        <div className="relative bg-custom-opacity ">
            {isNotLoggedIn && <LoginPromptModal visible={isNotLoggedIn} />}

            {!isMobile && showTopNavBar && (
                <TopNavBar
                    canNavigateHome={true}
                    sidebarCollapse={false}
                    isMobile={isMobile}
                    showHistoryOption={false}
                    breadCrumbPage={pageFrom ?? undefined}
                    setChatList={() => {}}
                />
            )}
            {isMobile && showTopNavBar && (
                <div className="sticky top-0 z-[200]">
                    <TopNavMobile
                        title="Editor"
                        onBack={() => {
                            setShowSidebar(true);
                            if (pageFrom == null) {
                                navigate('/dashboard/article');
                            } else {
                                navigate(-1);
                            }
                        }}
                        options={['Comments', 'Resources']}
                        selectedOption={(option: string) => {
                            if (option === right.name) {
                                setRight(() => {
                                    return { name: '', placeholder: '' };
                                });
                            }
                            setMiddleOption('');
                            handleMiddleOption(option);
                        }}
                        selected={(language: string) => handleTranslate(language)}
                        showActions={true}
                        canHumanize={canHumanize}
                        canTranslate={canTranslate}
                        loading={translated}
                        onHumanizeClick={() => {
                            handleHumanize();
                        }}
                        humanized={!humanizing}
                    />
                </div>
            )}

            {/* <div className="flex flex-row py-3 justify-center">
                {isTyping && <ContentTypingComponent isTyping={isTyping} />}
            </div> */}

            {/* <BackButton onclick={() => {
                 console.log('back');
                 setGenerate(false);
                 setResponse('');
                 setShowSidebar(true);
                 setShowTop(true);

                 if (showingHistory) {
                     setShowingHistory(false);
                     if (generate) {
                         setGenerate(false);
                         setShowSidebar(true);
                         setShowTop(true);
                     }
                     return;
                 }


            }} /> */}

            {/* {showingHistory && (
                                    <div
                                        style={{
                                            height: '120px',
                                            overflowY: 'auto',
                                            backgroundColor: '#F9FAFC',
                                            fontFamily: 'Inter',
                                            fontSize: '0.8125rem',
                                            fontWeight: '400',
                                        }}
                                        className="w-full flex flex-row p-4 gap-6   w-full  "
                                    >
                                        <img src={Profile} className="h-8" alt="profile" />
                                        <span>{promptSent}</span>
                                    </div>
                                )} */}

            { edit && (
                <SubNavBar
                    isContent={isContent}
                    isTweaking={isTweaking}
                    handleChangeTone={handleChangeTone}
                    handleImproveQuality={handleImproveQuality}
                    handleLength={handleLength}
                    handleRewrite={handleRewrite}
                    onHumanizeClick={() => {
                        handleHumanize();
                    }}
                    // showActions={isOwnerk
                    isBack={isBack}
                    showActions={true}
                    humanized={!humanizing}
                    isTyping={isTyping}
                    onBack={() =>  {
                        console.log('back');
                        if(isMobile){setIsMobileStep?.(0);}
                        setGenerate(false);
                        setResponse('');
                        setShowSidebar(true);
                        setShowTop(true);
                        

                        if (showingHistory) {
                            setShowingHistory(false);
                            if (generate) {
                                setGenerate(false);
                                setShowSidebar(true);
                                setShowTop(true);
                            }
                            return;
                        }
                    }}
                    canHumanize={canHumanize}
                    canTranslate={canTranslate}
                    className={`${showTopNavBar ? 'mx-40' : ''}`}
                    loading={translated}
                    title={Document.current?.title?.slice(33)}
                    selected={(language: string) => handleTranslate(language)}
                />
            )}

            <div className={`  ${showTopNavBar ? 'mx-0 lg:mx-40' : 'min-h-screen mb-10 bg-white overflow-y-scroll  '}  `}>
                {content}
                {/* <TextEditor
                    humanized_content={humanizedContent}
                    translated_content={translatedContent}
                    isMobile={isMobile}
                    submitCommentButton={(v, obj) => {
                        handleCommentSubmission(v, obj);
                    }}
                    onHighlightingText={(e) => {}}
                    onCommentTextChange={(e) => {}}
                    MiddleComponent={
                        <Middle isMobile={isMobile} selectedOption={(v: string) => handleMiddleOption(v)} />
                    }
                    EndComponent={
                        <div className="flex">
                            <button className="bg-[var(--gmind-orange)] rounded-[0.45rem] px-3 py-1 font-normal text-[0.8rem] text-white">
                                    Paraphrase
                                </button>
                            <button
                                onClick={() => (humanizing ? null : handleHumanize())}
                                className={`${humanizing ? 'bg-[#f2f2f2] text-[#000000]' : 'bg-[var(--gmind-orange)] text-white'} rounded-[0.45rem] px-3 py-1 font-normal text-[0.8rem] `}
                            >
                                {humanizing ? 'Humanizing' : 'Humanize'}
                            </button>
                            {humanizing && <GiShipWheel className=" animate-spin absolute right-[-2rem] text-2xl" />}
                        </div>
                    }
                /> */}
            </div>

            <div
                style={
                    isMobile
                        ? { position: 'fixed', justifyContent: 'space-between', padding: '0 1rem' }
                        : { padding: '0 1rem', borderTop: '1px solid #f2f2f2' }
                }
                className={`gap-4 flex items-center  bg-white sticky  z-[100] bottom-0 left-0 right-0 ${showTopNavBar ? 'mx-0 lg:mx-40' : ''}`}
            >
                <div className="max-md:justify-between w-full flex items-center gap-4 p-[5px]">
                    <div className="flex items-start gap-x-5 w-[75%]">
                        {showInvite ? (
                            <button
                                onClick={handleInvite}
                                className="flex items-center gap-2 text-xs bg-gray-200 rounded-xl p-1 px-3"
                                type="button"
                                disabled={isTyping}
                            >
                                <UserAddOutlined style={{ fontSize: '12px', color: ' #E55109' }} />

                                <span>Invite</span>
                            </button>
                        ) : null}
                        <button
                            onClick={() => {
                                handleRefetchUnevenInitilDocumentFetch();
                                setShowDownload(true);
                            }}
                            disabled={isTyping}
                            className="flex items-center gap-2 text-xs bg-gray-200 rounded-xl p-1 px-3"
                            type="button"
                        >
                            <img className="w-3" src={download} alt="download" />
                            <span>Export</span>
                        </button>
                    </div>
                    <div className="flex gap-x-5 w-[30%] justify-end">
                        <button
                            onClick={handleShare}
                            className="flex items-center gap-2 text-xs bg-gray-200 rounded-xl p-1 px-3"
                            type="button"
                            disabled={isTyping}
                        >
                            <img className="w-3" src={share} alt="share" />
                            <span>Share</span>
                        </button>
                        {showInvite && !showingHistory ? (
                            <div
                                className="hidden md:flex items-center flex-row"
                                style={{ cursor: 'pointer' }}
                                onClick={() => regenrate()}
                                aria-disabled={isTyping}
                            >
                                <img src={Regenerate} alt="" />{' '}
                                <span className="font-normal text-[0.75rem] " style={{ marginLeft: '2px' }}>
                                    Regenerate
                                </span>
                                {isRegenerating && (
                                    <GiShipWheel className=" animate-spin absolute right-[-2rem] text-2xl" />
                                )}
                            </div>
                        ) : null}
                        {/* <img style={{ cursor: 'pointer' }} onClick={() => {}} src={Message} alt="" /> */}
                        {isLiked ? (
                            <LikedImage onClick={handledLike} />
                        ) : (
                            <img
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    handledLike();
                                }}
                                src={Like}
                                alt=""
                            />
                        )}

                        {disLike ? (
                            <DisLikeImage onClick={handledDisLike} />
                        ) : (
                            <img
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    handledDisLike();
                                }}
                                src={DisLike}
                                alt=""
                            />
                        )}
                    </div>
                </div>
            </div>

            {showShareModal && <Share url={UrlRef.current} onClose={() => setShowShareModal(false)} />}
            {isModalVisible && (
                <InviteModal
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    users={document}
                    docId={docId ?? ''}
                />
            )}
            {showDownload && (
                <Download
                    handlePDF={handlePDF}
                    handleWord={handleWord}
                    url={UrlRef.current}
                    onClose={() => setShowDownload(false)}
                    isPdfDownloading={pdfDownloading}
                    isWordDownloading={wordDownloading}
                />
            )}
        </div>
    );
};


export default NewEditorPage;
