import { Button, Form, Modal } from 'antd';
import { toast } from 'react-toastify';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import Input from '@src/pages/dashboard/components/Input';
import { FileContent, HistoryTypeFormat } from '@src/core/interfaces/chat';
import { ChatContent } from '@src/pages/dashboard/components/interface/interface';

import {
    FileTextOutlined,
    HighlightOutlined,
    EditOutlined,
    FilePdfFilled,
    CloseCircleOutlined,
    PaperClipOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import { MdFormatListBulleted } from 'react-icons/md';
import { Chats, ChatType, getHistory } from '@src/pages/dashboard/endpoints';
import { useDocumentContext } from '@src/context/DocumentContext';
import { useUserContext } from '@src/context/UseProvider';
import { BASE_URL } from '@src/utils/constants';

import { io, Socket } from 'socket.io-client';
import { useOutletContext, useNavigate } from 'react-router';
import { useDebouncer } from '@src/pages/dashboard/function/helper';
import { openCurriculumEditor } from '@src/utils/app_functions';
import { prompts } from '@src/utils/prompts';

import { v4 as uuidv4 } from 'uuid';
import NewEditorPage from '../../essay/editor/newInstanceOfEditor';
import Profile from '@assets/Avatar.svg';
import NewSunEditorComponent from '../../essay/sun_editor copy';
import BackButton from '@src/components/BackButton';
import { extractTextFromPDF, extractTextFromDOCX } from '@src/pages/dashboard/function/pdf_doc_helper';
import { chatRoutePages } from '@src/utils/app_routes';
import { CircularProgress } from '@mui/material';

interface UserDocOn {
    documentId: string;
    users: string[];
    admin: any;
    collaborators: any[];
}

const ResumeEnhancer = () => {
    const [selectedOptions, setSelectedOptions] = useState<[]>([]);
    const [uploadedFile, setUploadedFile] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [description, setDescription] = useState('');
    const [template, setTemplate] = useState('');
    const [isMobile, setIsMobile] = useState(true);
    const [histories, setHistories] = useState<any>([]);
    const [chatList, setChatList] = useState<ChatType[]>([]);
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });
    const [generate, setGenerate] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [promptSent, setPromptSent] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [socket, setSocket] = useState<Socket | null>(null);
    const [canNavigate, setCanNavigate] = useState(false);
    const [option, setOption] = useState('');
    const [docId, setDocId] = useState<string | undefined>(undefined);
    const [showingHistory, setShowingHistory] = useState(false);
    const [streamEndMessage, setStreamEndMessage] = useState<ChatType>({ role: 'assistant', content: '' });

    const [editorContent, setEditorContent] = useState<any>('');
    const [humanizedContent, setHumanizedContent] = useState<any>();
    const [translatedContent, setTranslatedContent] = useState<any>();
    const [editorData, setEditorData] = useState('');
    const { loading, documentResponse } = useDocumentContext();
    const { userDetails } = useUserContext();
    const [userOnDoc, setUserOnDoc] = useState<UserDocOn>();
    const editorInstanceRef = useRef<any>();
    const steps = [
        ' Tailor Your Resume to the Role',
        'Reformat resume structure',
        'Optimize for keywords',
        'Error-Free Proofreading',
    ];

    const {
        setShowTop,
        setShowSidebar,
        setHistory,
        setMobileNavStyle,
        setShowPagePath,
        setShowHistoryOption,
        setShowRightComponent,
        setShowHistory,
        response,
        setResponse,
        details,
    } = useOutletContext<any>();

    const pageHistory = 'essay';
    const navigate = useNavigate();
    const baseurl = BASE_URL;
    const bottomRef = useRef<HTMLDivElement>(null);
    const [highlightedTexts, setHighlightedTexts] = useState<string>('');
    const [latestRespone, setLatestRespone] = useState<string>('');
    const [userInput, setUserInput] = useState('');
    const [isloading, setisLoading] = useState(false);
    const [extractedFile, setExtractedFile] = useState<string>('');

    const [currentStep, setCurrentStep] = useState<number>(1);
    const handleStepChange = (step: number) => setCurrentStep(step);

    const handleCardClick = (option: string) => {
        setSelectedOptions((prevOptions) => {
            if (prevOptions.includes(option)) {
                // If the option exists, remove it
                return prevOptions.filter((opt) => opt !== option);
            } else {
                // If the option doesn't exist, add it
                return [...prevOptions, option];
            }
        });

        setTemplate((prev: any) =>
            prev === null
                ? template
                : typeof prev === 'string'
                  ? template
                  : prev.includes(template)
                    ? prev.filter((o) => o !== template)
                    : [...prev, template],
        );
        console.log(template);
    };

    const optionStyle = (template: string) =>
        `px-4 py-6 rounded-md shadow-sm cursor-pointer flex flex-col items-center text-center
      ${selectedOptions.includes(template) ? 'bg-gray-200 border-[0.2rem] border-blue-500' : 'bg-gray-100 hover:bg-gray-200'}`;

    const handleOk = () => {
        if (template === 'Tailor Your Resume to the Role' && !description.trim()) {
            toast.error('Please input a job description before proceeding.');
            return;
        }
        setIsModalVisible(false);
    };

    const handleOptionClick = (template: string) => {
        const isModalDismissed = localStorage.getItem('showAttachDocumentModal');
        if (!isModalDismissed && !uploadedFile) {
            setIsModalVisible(true);
        }

        setSelectedOption(template);
        setTemplate(template);

        if (template === 'Tailor Your Resume to the Role') {
            setIsModalVisible(true);
        }
    };

    const handleDontShowAgain = () => {
        localStorage.setItem('showAttachDocumentModal', 'true');
        setIsModalVisible(false);
    };

    useEffect(() => {
        const newSocket = io(baseurl.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    useEffect(() => {
        if (!socket) return;

        socket.on('data', (data: string) => {
            setResponse((response: string) => response + data);
            console.log('Received data:', data);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            // setShowEditorButton(true);
            const { assistant } = data;
            setStreamEndMessage(assistant);
            setIsTyping(false);
            setCanNavigate(true);
            // handle stream end
            const uuid = uuidv4();
            setDocId(uuid);

            const user = JSON.parse(localStorage.getItem('user') || '');
            try {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: data.assistant,
                    owner_id: user?.id,
                });
            } catch (error) {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: data.assistant,
                });
            }
            socket?.emit('get-documents', user?.id);
        });

        return () => {
            socket.off('message');
        };
    }, [socket]);

    const sendChanges = useDebouncer((documentId: string, userId: string, data: string) => {
        console.log('sendChanges', documentId, userId, data);
        socket?.emit('send-changes', {
            documentId,
            userId,
            data,
        });
    }, 1000);

    const removeQueryParameter = (key: string) => {
        const url = new URL(window.location.href);
        url.searchParams.delete(key); // Remove the query parameter
        window.history.pushState({}, '', url.toString()); // Update the URL without navigation
    };

    function getDocIdFromUrl() {
        try {
            const urlObject = new URL(window.location.href); // Parse the URL
            const doc = urlObject.searchParams.get('document');
            setDocId(doc ?? '');
        } catch (error) {
            console.error('Invalid URL', error);
            return null;
        }
    }

    useEffect(() => {
        console.log(generate);

        if (generate || showingHistory) {
            openCurriculumEditor({
                response,
                pageId: docId,
                promptSent,
                promptType: prompts.curriculum,
                navigate,
                pageSocket: socket,
            });
            getDocIdFromUrl();
        } else {
            removeQueryParameter('document');
        }
    }, [generate, showingHistory]);

    const getPageHistory = useCallback(() => {
        getHistory(pageHistory).then((response) => {
            if (response?.statusCode === 1) {
                const data = response?.data;
                const filtered: HistoryTypeFormat[] = data?.map((d: any) => {
                    return {
                        date: d.date,
                        histories: d.histories?.map((history: any) => {
                            return [
                                ...history.conversation,
                                { chat_id: history?.chat_id },
                                { id: history._id },
                                { date: history.updatedAt },
                            ];
                        }),
                    };
                });

                console.log(filtered);
                setHistories(filtered);
            } else {
                setHistories([]);
            }
        });
    }, [pageHistory, setHistories]);

    useEffect(() => {
        if (generate || showingHistory) {
            setShowTop(false);
            setShowSidebar(false);
        } else {
            setShowTop(true);
            setShowSidebar(true);
        }
    }, [generate, showingHistory]);

    useEffect(() => {
        if (response) {
            console.log(response);
            setEditorContent(response);
            setTranslatedContent(response);
        }
    }, [response]);

    const onGenerateHandler = async (message: string) => {
        if (!message) {
            toast.error("Input can't be empty");
            return;
        }
        toast.info('Please sit tight, your beautiful content is on its way.');
        // setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: message, type: 'gpt-4' };
        setChats(msgs);
        setResponse('');
        setIsTyping(true);

        try {
            socket?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',
                            //   content: `You are G-Mind. You can help with all educational or academic questions or tasks`,
                            content: systemPrompt,
                            type: 'gpt-4',
                        },
                        msgs,
                    ],
                    userId: details?.userId,
                },
            });
            // await handleAddHistory([{ role: 'user', content: message }, msgs], pageHistory, {});
            // getPageHistory();
        } catch (error) {
            // setIsTyping(false);
        } finally {
            // setIsTyping(false);
        }
    };

    const onFinish = (values: string) => {
        const promptMessage = `
        You are a professional resume enhancer.
        Follow the instructions below:
        Using this ${String(selectedOptions)}, enhance the resume content provided:
        ${
            uploadedFile
                ? `Template: ${String(selectedOptions)}. The uploaded file content is attached below. ${uploadedFile}`
                : `Enhance the following resume content: ${values} using the ${String(selectedOptions)} template.`
        }

        - Job Description: Use ${values || uploadedFile} to guide the enhancement.
        - Resume Content: ${extractedFile}


        `;

        onGenerateHandler(promptMessage);
        setGenerate(true);
        setGenerating(true);
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, condition: string) => {
        const fileInput = event.target;
        const file = fileInput.files?.[0];

        console.log('Selected file:', file);
        setisLoading(true);

        if (!file) return;
        console.log('Selected file:', file);

        const fileType = file.type;

        if (
            fileType === 'application/pdf' ||
            fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
            // Handle PDF and DOCX files
            const fileTypeValue: 'PDF' | 'DOCX' = fileType === 'application/pdf' ? 'PDF' : 'DOCX';
            try {
                // }

                if (fileType === 'application/pdf') {
                    const doc = await extractTextFromPDF(file);
                    if (condition === 'description') {
                        setUploadedFile(doc);
                    } else {
                        setExtractedFile(doc);
                    }
                    setUserInput(doc);
                    console.log(doc);
                    console.log('exract', extractedFile);
                } else {
                    const doc = await extractTextFromDOCX(file);
                    if (condition === 'description') {
                        setUploadedFile(doc);
                    } else {
                        setExtractedFile(doc);
                    }
                    console.log(doc);
                    setUserInput(doc);
                }
            } catch (error) {
                console.error('Error during file upload:', error);
                toast.error('File upload failed');
            } finally {
                setisLoading(false);
            }
        } else {
            console.warn('Unsupported file type:', fileType);
            return;
        }
    };

    return (
        <div
            className={`${!generate && !showingHistory ? 'px-10' : ''} w-full flex flex-col h-full overflow-y-auto md:h-screen items-start`}
        >
            <div className="flex flex-row items-start justify-start mt-5 w-full">
                {!generate && !showingHistory && (
                    <BackButton onclick={() => navigate(`/${chatRoutePages.studentResources}`)} />
                )}
            </div>
            {!generate && !showingHistory ? (
                <>
                    {' '}
                    <div className="flex flex-col gap-y-5 items-start">
                        <div className="flex flex-col  items-start text-start py-3">
                            <h1 className="text-3xl font-bold text-gray-800 dark:text-white">Resume Enhancer</h1>
                            <p className="text-gray-600 mt-2 dark:text-white">
                                Transform Your CV into a Powerful Career Tool{' '}
                            </p>
                        </div>

                        {/* Steps Navigation */}

                        {/* Current Step */}
                        <div className="flex flex-col items-start mt-5 w-full">
                            {currentStep === 1 && (
                                <div className="w-full max-w-5xl">
                                    <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-4">
                                        Enter Your Job Description
                                    </h2>
                                    <div className="flex flex-row items-center gap-3 w-full border border-gray-700 dark:border-gray-300 p-4 rounded-lg focus-within:ring-2 focus-within:ring-blue-500">
                                        <div className="relative flex-grow">
                                            {!isloading ? (
                                                <label className="flex items-center text-gray-600 dark:text-gray-300 cursor-pointer">
                                                    {uploadedFile ? (
                                                        <div className="relative flex items-center justify-start w-20 h-12 bg-white rounded-md shadow-md border border-gray-300">
                                                            {/* PDF Icon */}
                                                            <FilePdfFilled className="text-2xl text-red-600 z-10 ml-4" />

                                                            {/* Close Icon */}
                                                            <CloseCircleOutlined
                                                                className="absolute top-0 right-0 z-[100] text-red-500 cursor-pointer hover:text-red-700"
                                                                onClick={() => setUploadedFile('')}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <input
                                                                type="file"
                                                                accept=".pdf,.docx"
                                                                onChange={(e) => handleFileChange(e, 'description')}
                                                                className="hidden"
                                                            />
                                                            <PaperClipOutlined className="absolute top-[1rem] text-gray-600 dark:text-gray-200 z-8 text-2xl" />
                                                        </>
                                                    )}
                                                </label>
                                            ) : (
                                                <div className="h-6 w-6 flex items-center justify-center">
                                                    <CircularProgress className="text-md" />
                                                </div>
                                            )}
                                            <textarea
                                                value={inputValue}
                                                onChange={(e) => setInputValue(e.target.value)}
                                                className="w-full mt-2 pr-12 pl-10 rounded-lg border-none text-black dark:text-white focus:outline-none placeholder-gray-600 dark:placeholder-gray-300 "
                                                placeholder="Attach your job description here or type it"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {currentStep === 2 && (
                                <div className="w-full max-w-5xl">
                                    <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-4">
                                        Attach Resume
                                    </h2>
                                    <div className="w-full max-w-5xl border border-gray-300 rounded-lg shadow-md p-4 flex flex-row justify-center items-center">
                                        {!isloading ? (
                                            <label className="flex items-center text-gray-600 dark:text-gray-300 cursor-pointer">
                                                {extractedFile ? (
                                                    <div className="relative flex items-center justify-start w-40 h-12 bg-white rounded-md shadow-md border border-gray-300">
                                                        {/* PDF Icon */}
                                                        <FilePdfFilled className="text-2xl text-red-600 z-10 ml-4" />

                                                        {/* Close Icon */}
                                                        <CloseCircleOutlined
                                                            className="absolute top-0 right-0 z-[100] text-red-500 text-2xl cursor-pointer hover:text-red-700"
                                                            onClick={() => setExtractedFile('')}
                                                        />
                                                    </div>
                                                ) : (
                                                    <>
                                                        <input
                                                            type="file"
                                                            accept=".pdf,.docx"
                                                            onChange={(e) => handleFileChange(e, 'resume')}
                                                            className="hidden"
                                                        />
                                                        <UploadOutlined className="top-[1rem] text-gray-600 dark:text-gray-200 text-5xl" />
                                                    </>
                                                )}
                                            </label>
                                        ) : (
                                            <div className="h-6 w-6 flex items-center justify-center">
                                                <CircularProgress className="text-md" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {currentStep === 3 && (
                                <div className="grid grid-cols-2 gap-4 md:grid-cols-4 w-full lg:max-w-5xl ">
                                    {[
                                        ' Tailor Your Resume to the Role',
                                        'Reformat resume structure',
                                        'Optimize for keywords',
                                        'Error-Free Proofreading',
                                    ].map((op) => (
                                        <div
                                            key={op}
                                            className={optionStyle(op)}
                                            onClick={() => {
                                                console.log('Option:', op);
                                                handleCardClick(op);
                                            }}
                                        >
                                            <span className="text-gray-800">{op}</span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div className="flex flex-row gap-4">
                            {/* Input field always pinned to bottom */}

                            {currentStep === 2 && (
                                <button
                                    className={`mr-2 px-4 mt-4  py-2 text-sm font-medium rounded ${
                                        currentStep === 1
                                            ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                                            : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                                    }`}
                                    onClick={() => currentStep > 1 && handleStepChange(currentStep - 1)}
                                    disabled={currentStep === 1}
                                >
                                   Previous
                                </button>
                            )}

                            {currentStep === 3 && (
                                <button
                                    className={`mr-2 px-4 mt-4  py-2 text-sm font-medium rounded ${
                                        currentStep === 2
                                            ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                                            : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                                    }`}
                                    onClick={() => currentStep > 1 && handleStepChange(currentStep - 1)}
                                    disabled={currentStep === 2}
                                >
                                    Previous
                                </button>
                            )}
                            {currentStep === 3 && (
                                <div className="flex mt-4  flex-row gap-3 items-center ">
                                    {currentStep === 3 && (
                                        <button
                                            onClick={() => {
                                                if (!inputValue || !selectedOptions || !extractedFile) {
                                                    toast.info('Please upload a file or provide a template');
                                                    return;
                                                }

                                                onFinish(inputValue);
                                            }}
                                            className="px-4 py-2 rounded bg-customOrange text-white w-full md:w-auto"
                                        >
                                            {' '}
                                            Generate
                                        </button>
                                    )}
                                </div>
                            )}

                            {currentStep === 1 && (
                                <div className="flex flex-row justify-start  mt-4">
                                    <button
                                        className={`mx-2 px-4 py-2 text-sm font-medium rounded ${
                                            currentStep === steps.length
                                                ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                                                : 'bg-customOrange text-white '
                                        }`}
                                        onClick={() => {
                                            if (currentStep === 1 && !inputValue) {
                                                toast.error('Please enter attach or enter job description');
                                                return;
                                            }

                                            currentStep < steps.length && handleStepChange(currentStep + 1);
                                        }}
                                        disabled={currentStep === steps.length}
                                    >
                                        Next
                                    </button>
                                </div>
                            )}

                            {currentStep === 2 && (
                                <div className="flex flex-row justify-start  mt-4">
                                    <button
                                        className={`mx-2 px-4 py-2 text-sm font-medium rounded ${
                                            currentStep === steps.length
                                                ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                                                : 'bg-customOrange text-white '
                                        }`}
                                        onClick={() => {
                                            if (currentStep === 2 && !extractedFile) {
                                                toast.error('Please upload a file');
                                                return;
                                            }

                                            currentStep < steps.length && handleStepChange(currentStep + 1);
                                        }}
                                        disabled={currentStep === steps.length}
                                    >
                                        Next
                                    </button>
                                </div>
                            )}
                        </div>
                        {isModalVisible && (
                            <Modal
                                title="Resume Enhancer"
                                open={isModalVisible}
                                onOk={handleOk}
                                onCancel={() => setIsModalVisible(false)}
                                footer={[
                                    <Button key="dontShow" onClick={handleDontShowAgain}>
                                        Don't Show Again
                                    </Button>,
                                    <Button key="ok" type="primary" onClick={handleOk}>
                                        OK
                                    </Button>,
                                ]}
                            >
                                {template === 'Tailor Your Resume to the Role' ? (
                                    <p>Please input a job description to tailor your resume to the role.</p>
                                ) : (
                                    <p>Please upload a resume document or write content to enhance your resume.</p>
                                )}
                                <textarea
                                    className="w-full h-32 p-2 border border-gray-300 rounded-md mt-3"
                                    placeholder="Enter job description here"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </Modal>
                        )}
                    </div>
                </>
            ) : (
                <div className="w-full flex flex-col gap-0 ">
                    <NewEditorPage
                    setDocId={setDocId}
                    docId={docId}
                        editorInstanceRef={editorInstanceRef}
                        content={
                            <NewSunEditorComponent
                                latestRespone={latestRespone}
                                setLatestRespone={setLatestRespone}
                                highlightedTexts={highlightedTexts}
                                setHighlightedTexts={setHighlightedTexts}
                                editorInstanceRef={editorInstanceRef}
                                // humanizeded_content={Document.current?.data.ops}
                                // hasAccess={hasAccess}
                                hasAccess={!isTyping && (documentResponse?.role ?? '') !== 'view' ? true : false}
                                initial_content={response}
                                onChangeEditorContent={(e: string) => {
                                    setEditorContent(e);
                                    const newtext = e;
                                    console.log('newtext', newtext);

                                    console.log('docId', docId);
                                    console.log('userDetails?.userId', userDetails?.userId);

                                    sendChanges(docId, userDetails?.userId, e);
                                }}
                            />
                        }
                        showTopNavBar={false}
                        showInvite={true}
                        isTyping={isTyping}
                        Profile={Profile}
                        promptSent={promptSent}
                        showingHistory={showingHistory}
                        setGenerate={setGenerate}
                        setShowingHistory={setShowingHistory}
                        generate={generate}
                        setEditorContent={setEditorContent}
                        editorContent={editorContent}
                        translatedContent={translatedContent}
                        setTranslatedContent={setTranslatedContent}
                        humanizedContent={humanizedContent}
                        setHumanizedContent={setHumanizedContent}
                        setUserOnDoc={setUserOnDoc}
                        userOnDoc={userOnDoc}
                        latestRespone={latestRespone}
                        setLatestRespone={setLatestRespone}
                        highlightedTexts={highlightedTexts}
                        setHighlightedTexts={setHighlightedTexts}
                        regenerate={onFinish}
                    />
                </div>
            )}
        </div>
    );
};

export default ResumeEnhancer;

const systemPrompt = `
You are an AI-powered Resume Enhancer. Your goal is to provide comprehensive, actionable suggestions to improve resumes by focusing on:

1. **Highlighting Achievements**: Identify and emphasize the candidate's most impactful accomplishments.
2. **Enhancing Structure**: Reformat the resume for clarity, professionalism, and easy readability.
3. **Keyword Optimization**: Suggest industry-specific keywords to increase visibility in applicant tracking systems (ATS) and by recruiters.
4. **Concise Summaries**: Create impactful, succinct summaries for each section of the resume.
5. **Professional Standards**: Ensure grammar, tone, and style adhere to professional norms.

**Response Guidelines**:
- **Detailed Analysis**: Provide in-depth analysis of the resume's content, structure, and language.
- **Actionable Enhancements**: Offer clear, specific suggestions for improvement, focusing on clarity, relevance, and conciseness.
- **Tailored Feedback**: Customize feedback based on the candidate’s career goals, target roles, or industries.
- **Constructive and Positive**: Ensure all feedback is constructive, precise, and encourages improvement without rewriting unless explicitly requested.
- **Respectful Tone**: Maintain a respectful, professional tone throughout the feedback process.
- It should be well formatted with proper spacing and formatting.
**Response Format**:
1. **Enhanced Resume**:
    - Use clear section headers (e.g., PROFILE, EXPERIENCE, SKILLS, EDUCATION).
    - Present information in a well-organized, easy-to-read format with consistent alignment and spacing.
2. **Career Enhancement Suggestions**:
    - Provide targeted suggestions to improve weak sections or add missing information.
    - Include strategies for optimizing content with industry-specific keywords and improving overall resume effectiveness.

Example:

---

### **Professional Summary**  
{Provide a concise summary of professional expertise, highlighting experience in GRC frameworks, regulatory compliance, IT auditing, and risk management. Example:  
"As a seasoned Senior GRC Expert, I specialize in designing and implementing Governance, Risk, and Compliance (GRC) platforms, enhancing IT control ecosystems, and mitigating risks. Proficient in frameworks like COSO, COBIT, ISO 27001, and NIST, I drive operational efficiency and improve IT infrastructure security."}  

---

### **Skills**  
List technical and professional skills relevant to IT GRC and auditing:  
- Governance, Risk & Compliance Frameworks (e.g., COSO, COBIT, ISO 27001, NIST)  
- IT General Controls Review & SOX Compliance  
- Regulatory Compliance (NIST, GDPR, PCI-DSS, SOC 2, SOX)  
- IT Infrastructure Auditing (OS, Database, Network, Active Directory)  
- Expertise in GRC Tools (AuditBoard, ServiceNow GRC, RSA Archer, IDEA, ACL)  
- Cybersecurity Controls Assessment and Improvement  
- Business Continuity/Disaster Recovery Planning  

---

### **Certifications**  
List relevant certifications and training:  
- Certified Information System Auditor (CISA) – In View  
- Certified Information Security Manager (CISM) – In View  
- AWS Certified Solutions Architect – Associate  
- Scrum Master  
- Six Sigma – Yellow Belt  

---

### **Experience**  
Structure work experience as follows:  
- **[Job Title] | [Company Name] | [Dates]**  
  - [Key achievement or responsibility #1]  
  - [Key achievement or responsibility #2]  
  - [Key achievement or responsibility #3]  

Example:  
**Senior IT Audit / Compliance Specialist | Zachsam | Aug 2021 – Present**  
- Assessed IT General Controls (ITGC) to maintain 100% SOX compliance.  
- Designed risk-based IT audit programs using ISO 27001 and NIST frameworks, improving business continuity.  
- Reduced audit planning time by streamlining processes with the SOX IT Audit Board.  

---

### **Education**  
[Degree] in [Field of Study] | [University Name], [Location]  

Example:  
Bachelor of Engineering in Mechanical Engineering | Enugu State University of Science & Technology, Nigeria  

---


Output Format:
---

# TAIWO ANUOLUWAPO  
## Software Developer 

---

### Contact  
- **Mobile:** 07039546455  
- **Email:** [anutaiwo16@gmail.com](mailto:anutaiwo16@gmail.com)  
- **LinkedIn:** [Taiwo Anu](https://www.linkedin.com/in/taiwo-anu-83032916b)  
---

### Profile  
Seasoned Software Developer specializing in the creation of robust, efficient digital experiences. Well-versed in Dart, SCSS, Tailwind CSS, React, TypeScript, CSS, Bootstrap, HTML, Flutter, NodeJS, MongoDB, with a proven track record of developing visually compelling and high-functioning applications. Adept at thriving in collaborative environments, with a continuous drive for adoption and learning of new technologies to enrich skill set.

### Skills  
- React, TypeScript, CSS, SCSS, HTML  
- Dart and Flutter Development  
- Riverpod State Management  
- Bootstrap and Tailwind CSS  
- Firebase, NodeJS, MongoDB  
- Data Annotation, SRT  

### Experience  
#### Mobile App Developer | Govest | 2024 - Present  
- Developed a fintech mobile application providing quasi-banking services, resulting in enhanced financial solutions for businesses and organizations in Africa.  
- Executed comprehensive mobile application testing across various platforms, ensuring flawless software operation.  

#### Software Engineer | GotoCourse | 2024 - Present  
- Streamlined the platform's collaboration and communication features by implementing real-time speech-to-text using speech recognition.  
- Developed a robust coach booking app using React, TypeScript, and Ant Design, facilitating efficient coach discovery, booking, and management.  

#### Shopping API Developer | HolaChats | 2023 - 2024  
- Built a RESTful shopping API with secure endpoints for managing items, categories, user carts, and payments, enhancing user experience and transaction security.  
- Utilized MongoDB for efficient data storage and access, ensuring API performance and scalability.  

#### Data Annotation Specialist | Appen | 2022-2023  
- Performed various data annotation tasks using specific tools and software based on project requirements, including image, text, and audio annotations.  

### Education  
**Bachelor of Engineering** | Federal University of Technology Akure | 2015 - 2021  

### Languages  
- **English:** Fluent
---





`;
